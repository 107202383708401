import React, { Component } from 'react'
import api from '../../../api'
import cookie from 'react-cookies'
const queryString = require('query-string')



class Home extends Component {
  constructor (props) {
    super(props)

    this.state = {
      winner_active: true,
      plan_active: false,
      statistic_active: false
    }
  }
  
  async componentDidMount() {
    const parsed = queryString.parse(this.props.history.location.search)
    if(parsed.token) {
      const current = new Date()
      current.setHours(current.getHours() + 2)
      cookie.save('token', parsed.token, {path: '/', expires: current})
      this.props.history.push('/')
    }
    
    if(cookie.load('token')) {
      try {
        const data = await api.get('/my/customer', {headers: {authorization: cookie.load('token')}})
        console.log(data.data.data)
        this.setState({
          customer: data.data.data
        })
      } catch(e) {
        cookie.remove('token', { path: '/' })
      }
    }
  }

  switchPane(e) {
    if(e === 'winner_active') {
      this.setState({
        winner_active: true,
        plan_active: false,
        statistic_active: false
      })
    }
    if(e === 'plan_active') {
      this.setState({
        winner_active: false,
        plan_active: true,
        statistic_active: false
      })
    }
    if(e === 'statistic_active') {
      this.setState({
        winner_active: false,
        plan_active: false,
        statistic_active: true
      })
    }
  }

	render() {
    const { width, somio_data } = this.props
    const { winner_active, plan_active, statistic_active, customer } = this.state


    if(width <= 768) {
      return (
        <div className="breakpoint active" id="bp_768" >
          <div className="clearfix borderbox"  id="page">
            <div className="clearfix grpelem" id="ptop"> <a className="anchor_item colelem shared_content" id="top"></a>
              <div className="browser_width colelem" style={{height: "653px"}} id="u99590-bw">
                <div className="rounded-corners" id="u99590">
                  <div className="clearfix" id="u99590_align_to_page"> <a className="anchor_item grpelem shared_content"
                      id="ueber"></a>
                    <div className="clearfix grpelem shared_content" id="ppu513-6">
                      <div className="clearfix colelem" id="pu513-6">
                        <div className="h1---display-left-dark clearfix grpelem" id="u513-6">
                          <h1 id="u513-2">Misez tous les jours pour</h1>
                          <h1 id="u513-4">essayer de gagner tous les jours !</h1>
                        </div>
                        <div className="h2---display-left-dark clearfix grpelem" id="u514-10">
                          <h2 id="u514-6"><br/>Tentez votre chance pour remporter <br />une rente mensuelle d'une valeur totale <br />d'un million d'euros !</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix colelem" id="ppu540-6">
                <div className="clearfix grpelem" id="pu540-6">
                  <div className="clearfix colelem shared_content" id="u540-6">
                    <div className="h1---display-left-dark" id="u540-5">
                      <h1 id="u540-2">Jouer en</h1>
                      <h1 id="u540-4">toute confiance.</h1>
                    </div>
                  </div>
                  <div className="body clearfix colelem shared_content" id="u80669-7">
                    <p>Sur www.unmillionparjour, vous avez chaque jour la chance de remporter un million d'euros. Il vous suffit simplement de miser vos six numéros porte-bonheur et un numéro complémentaire. Tentez votre chance pour remporter une rente mensuelle d'une valeur totale d'un million d'euros !</p>
                    <p>&nbsp;</p>
                    <p><span id="u80669-4">Rien de plus simple : misez, inscrivez-vous et croisez les doigts pour gagner !</span></p>
                  </div>
                </div>
                <div className="body clearfix grpelem shared_content" id="u1194-6">
                  <p id="u1194-2">JOUEZ TOUS LES JOURS POUR ESSAYER DE GAGNER</p>
                  <p>Une fois par jour, vous pouvez miser votre grille personnelle chez Unmillionparjour. Toutes les combinaisons quotidiennes sont ensuite collectées et participent à un tirage qui a lieu 28 jours après l'activation de la combinaison. Pour activer les numéros de la combinaison, il est indispensable de finaliser le processus de confirmation d'inscription. À cet effet, vous recevrez après la validation de la combinaison un e-mail de confirmation à l'adresse e-mail que vous avez renseignée, qui comporte un lien de confirmation. Afin de finaliser le processus, vous devez cliquer sur ce lien de confirmation dans les 24 heures suivant la réception dudit e-mail. Si vous ne cliquez pas le lien dans le délai imparti, annulant ainsi le processus de confirmation, votre combinaison ne sera pas validée, et ne pourra donc pas participer au tirage au sort. Vous pouvez aisément visualiser les numéros gagnants dans la partie Statistiques d'Unmillionparjour.
                  </p>
                </div>
              </div><a className="nonblock nontext Button anim_swing btn-ghost-dark clearfix colelem shared_content"
                id="buttonu3678" href="/#Rang des gains">
                <div className="clearfix grpelem" id="u3679-4">
                  <p>RANG DES GAINS</p>
                </div>
              </a>
              <div className="browser_width colelem" style={{height: "670px"}} id="u1239-bw">
                <div className="museBGSize" id="u1239">
                  <div className="clearfix" id="u1239_align_to_page"> <a className="anchor_item grpelem shared_content"
                      id="Rang des gains"></a>
                    <div className="clearfix grpelem" id="pu1305">
                      <div className="museBGSize rounded-corners colelem shared_content" id="u1305"></div>
                      <div className="h1---display-left-light clearfix colelem shared_content" id="u99429-8">
                        <h1 id="u99429-2">Mon conseil :</h1>
                        <h1 id="u99429-6">Misez tous les jours pour <br />essayer de gagner tous les jours </h1>
                      </div><a
                        className="nonblock nontext Button anim_swing transition rgba-background clearfix colelem shared_content"
                        id="buttonu1296" href="/#fragen">
                        <div className="clearfix grpelem" id="u1297-4">
                          <p>DES QUESTIONS?</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix colelem" id="pu5481">
                <div className="browser_width grpelem shared_content" style={{height: "418px"}} id="u5481-bw">
                  <div className="gradient" id="u5481"></div>
                </div>
                <div className="browser_width grpelem" style={{height: "169px"}} id="u5484-8-bw">
                  <div className="clearfix shared_content" id="u5484-8">
                    <h1 className="h1---display-left-light" id="u5484-2">Rang des gains</h1>
                    <h2 className="h2---display-center-light" id="u5484-6">Consultez les résultats des tirages  <br />actuels 
                      et passés.</h2>
                  </div>
                </div>
                <div className="PamphletWidget clearfix allow_click_through grpelem"  id="pamphletu95319">
                  <div className="position_content" style={{pointerEvents: "none"}} id="pamphletu95319_position_content">
                    <div className="ThumbGroup clearfix colelem" id="u95339" style={{pointerEvents: "none", height: "81px"}}>
                      <div className="popup_anchor allow_click_through grpelem" id="u95344popup"
                        style={{height: "81px", pointerEvents: "none"}} onClick={(e) => this.switchPane('winner_active')}>
                        <div className={winner_active ? "Thumb popup_element rounded-corners clearfix wp-tab-active PamphletThumbSelected" : "Thumb popup_element rounded-corners clearfix"}
                          style={{height: "64px"}} id="u95344">
                          <div className="transition clearfix grpelem shared_content" id="u95345-7">
                            <p id="u95345-3"><span id="u95345">&#8203;</span><span className="actAsInlineDiv normal_text"
                                id="u95346"><span className="actAsDiv excludeFromNormalFlow" id="u95347"><i
                                    className="fa fa-calculator"></i>
                                </span></span></p>
                            <p id="u95345-5">RÉSULTATS ACTUELS</p>
                          </div>
                        </div>
                      </div>
                      <div className="popup_anchor allow_click_through grpelem" id="u95348popup"
                        style={{height: "81px", pointerEvents: "none"}} onClick={(e) => this.switchPane('plan_active')}>
                        <div className={plan_active ? "Thumb popup_element rounded-corners clearfix wp-tab-active PamphletThumbSelected" : "Thumb popup_element rounded-corners clearfix"} style={{height: "66px"}} id="u95348">
                          <div className="transition clearfix grpelem shared_content" id="u95349-7">
                            <p id="u95349-3"><span id="u95349">&#8203;</span><span className="actAsInlineDiv normal_text"
                                id="u95350"><span className="actAsDiv excludeFromNormalFlow" id="u95351"><i
                                    className="fa fa-database"></i>
                                </span></span></p>
                            <p id="u95349-5">RANG DES GAINS</p>
                          </div>
                        </div>
                      </div>
                      <div className="popup_anchor allow_click_through grpelem" id="u95340popup"
                        style={{height: "81px", pointerEvents: "none"}}>
                        <div className={statistic_active ? "Thumb popup_element rounded-corners clearfix wp-tab-active PamphletThumbSelected" : "Thumb popup_element rounded-corners clearfix"} style={{height: "66px"}} id="u95340">
                          <div className="transition clearfix grpelem shared_content" id="u95341-7" onclick={(e) => this.switchPane('statistic_active')}>
                            <p id="u95341-3" onclick={(e) => this.switchPane('statistic_active')}><span id="u95341">&#8203;</span><span className="actAsInlineDiv normal_text"
                                id="u95342"><span className="actAsDiv excludeFromNormalFlow" id="u95343"><i
                                    className="fa fa-graduation-cap" onclick={(e) => this.switchPane('statistic_active')}></i>
                                </span></span><span></span></p>
                            <p id="u95341-5" onclick={(e) => this.switchPane('statistic_active')}>HISTORIQUE DES TIRAGES</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="popup_anchor allow_click_through colelem" id="u95323popup"
                      style={{height: "644px", pointerEvents: "none"}}>
                      <div className="ContainerGroup clearfix" style={{pointerEvents: "none", height: "644px"}} id="u95323">
                        { statistic_active &&
                          <div className="Container rounded-corners featured-news-container clearfix grpelem wp-panel wp-panel-active" id="u95334" style={{height: "620px"}}>
                            <div className="clearfix grpelem shared_content" id="u95335-35">
                              <p className="h2" id="u95335-3">S<span id="u95335-2">TATISTIQUES</span></p>
                              <p className="body" id="u95335-7">Consultez les résultats des tirages  actuels  Gewinnzahlen
                                abrufen<br />et passés et vérifier si vos numéros en font partie.</p>
                                { somio_data &&
                                  <div dangerouslySetInnerHTML={{__html: somio_data.statistics}}>
                                  </div>
                                }
                             
                            </div>
                          </div>
                        }
                        { winner_active &&
                          <div className="Container featured-news-container clearfix grpelem wp-panel wp-panel-active" id="u95329" style={{height: "530px"}}>
                            <div className="clearfix grpelem shared_content" id="u95330-13">
                              <p className="h2" id="u95330-3">Dernier tirage  {somio_data && somio_data.winner_date}8&nbsp;&nbsp; <span
                                  id="u95330-2"></span></p>
                              <p className="body" id="u95330-7">{somio_data && somio_data.winner_numbers}<span id="u95330-5">{somio_data && somio_data.winner_star}</span></p>
                              <p className="body" id="u95330-8">&nbsp;</p>

                              { customer &&
                                <>
                                  <p className="h2" id="u95330-3">Votre combinaison</p>
                                  <p className="body" id="u95330-7" style={{letterSpacing: '3px'}}>{customer && customer.somioTips.slice(0,6).join('    |    ')}<span id="u95330-5">    |   {customer && customer.somioTips[6]}</span></p>
                                </>
                              }
                            </div>
                          </div>
                        }
                        { plan_active &&
                          <div className="Container featured-news-container clearfix grpelem wp-panel wp-panel-active" id="u95324" style={{height: "530px"}}>
                            <div className="clearfix grpelem shared_content" id="u95325-18">
                              <p className="h2" id="u95325-2">RANG DES GAINS</p>
                              <p className="body" id="u95325-4">Rang des gains vous trouverez ci-dessous notre rang des gains :</p>
                              <p className="body" id="u95325-6">6 bons numéros + numéro complémentaire : {somio_data && somio_data.plan_6_z} €</p>
                              <p className="body" id="u95325-7">&nbsp;</p>
                              <p className="body" id="u95325-9">6 bons numéros  : {somio_data && somio_data.plan_6} €</p>
                              <p className="body" id="u95325-10">&nbsp;</p>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div><a className="anchor_item grpelem shared_content" id="geht"></a>
              </div>
              <div className="clearfix colelem" id="pu6123-6">
                <div className="h1---display-left-light clearfix grpelem shared_content" id="u6123-6">
                  <h1 id="u6123-4">C'est aussi simple <br />que cela</h1>
                </div>
                <div className="clip_frame grpelem" id="u6120">
                  <img className="block" alt="" src="images/so-gehts.png?crc=4189511356" id="u6120_img" />
                </div>
              </div>
              <div className="body clearfix colelem shared_content" id="u6124-4">
                <p>CONNECTEZ-VOUS - MISEZ - JOUEZ</p>
              </div>
              <div className="rounded-corners clearfix colelem shared_content" id="u6232">
                <div className="clearfix grpelem" id="u55809"> 
                    <p className="body" id="u6152-3"><span id="u6152">1. </span>Connectez-vous ou bien inscrivez-vous si vous êtes nouveau sur Unmillionparjour. Si vous êtes nouveau chez nous, vous recevrez un e-mail de confirmation à l'adresse que vous avez renseignée, dans lequel nous vous invitons à cliquer sur le lien de confirmation pour activer votre compte.</p>
                    <p className="body" id="u6152-4">&nbsp;</p>
                    <p className="body" id="u6152-7"><span id="u6152-5">2. </span>Saisissez vos 6 numéros porte-bonheur et votre numéro complémentaire.</p>
                    <p className="body" id="u6152-8">&nbsp;</p>
                    <p className="body" id="u6152-11"><span id="u6152-9">3. </span>Confirmez votre combinaison en cliquant sur "Misez maintenant ". Vous recevrez un e-mail de notre part comportant vos numéros choisis et un lien de confirmation. En cliquant sur le lien de confirmation, vous serez redirigé vers une de nos pages Unmillionsparjour où vous devrez saisir le code affiché et le confirmer après l'avoir saisi. Après confirmation de la saisie du code, votre combinaison sera officiellement enregistrée et participera au tirage au sort 28 jours plus tard. Si le processus de confirmation n'est pas terminé dans les 24 heures suivant la réception du lien de confirmation, votre combinaison ne sera malheureusement pas prise en compte.</p>
                   </div>
              </div><a className="anchor_item colelem shared_content" id="fragen"></a>
              <div className="browser_width colelem shared_content" id="u6591-bw" style={{height: "2160px"}}>
                <div id="u6591">
                  <div className="clearfix" id="u6591_align_to_page">
                    <a className="anchor_item grpelem" id="anmelden"></a>
                    <div className="clearfix grpelem" id="pu86248-4">
                      <div className="h1---display-left-light clearfix colelem" id="u86248-4">
                        <h1 id="u86248-2">Foire Aux Questions</h1>
                      </div>
                      <div className="body clearfix colelem" id="u86240-78">
                        <p id="u86240-2"><span id="u86240">À quelle fréquence puis-je jouer?</span></p>
                        <p id="u86240-4">Une fois inscrit, vous pouvez valider votre combinaison auprès d'Unmillionparjour une fois par jour. Votre combinaison participe au tirage au sort 28 jours après la validation de la combinaison. Pour finaliser l'activation des numéros de votre combinaison, il est indispensable de finaliser le processus de confirmation d'inscription. À cet effet, vous recevrez après la validation de la combinaison un e-mail de confirmation à l'adresse e-mail que vous avez renseignée, qui comporte un lien de confirmation. Afin de finaliser le processus, vous devez cliquer sur ce lien de confirmation dans les 24 heures suivant la réception dudit e-mail. Si vous ne cliquez pas le lien dans le délai imparti, annulant ainsi le processus de confirmation, votre combinaison ne sera pas validée, et ne pourra donc pas participer au tirage au sort. </p>
                        <p id="u86240-5">&nbsp;</p>
                        <p id="u86240-7">Comment puis-je valider ma combinaison ?</p>
                        <p id="u86240-8">&nbsp;</p>
                        <p id="u86240-9">&nbsp;</p>
                        <ol className="list0 nls-None" id="u86240-20">
                          <li id="u86240-13">Connectez-vous ou bien inscrivez-vous si vous êtes nouveau sur Unmillionparjour. Si vous êtes nouveau chez nous, vous recevrez un e-mail de confirmation à l'adresse que vous avez renseignée, dans lequel nous vous invitons à cliquer sur le lien de confirmation pour activer votre compte.

<br /><br />
                          </li>
                          <li id="u86240-17">Saisissez vos 6 numéros porte-bonheur et votre numéro complémentaire.<br /><br /></li>
                          <li id="u86240-19">Confirmez votre combinaison en cliquant sur "Misez maintenant ". Vous recevrez un e-mail de notre part comportant vos numéros choisis et un lien de confirmation. En cliquant sur le lien de confirmation, vous serez redirigé vers une de nos pages Unmillionsparjour où vous devrez saisir le code affiché et le confirmer après l'avoir saisi. Après confirmation de la saisie du code, votre combinaison sera officiellement enregistrée et participera au tirage au sort 28 jours plus tard. Si le processus de confirmation n'est pas terminé dans les 24 heures suivant la réception du lien de confirmation, votre combinaison ne sera malheureusement pas prise en compte.</li>
                        </ol>
                        <p id="u86240-21">&nbsp;</p>
                        <p id="u86240-22">&nbsp;</p>
                        <p id="u86240-24">Jusqu'à quand puis-je valider ma combinaison ?</p>
                        <p id="u86240-26">Toutes les combinaisons d'un jour participent au tirage au sort 28 jours après la validation desdites combinaisons. Pour finaliser l'activation des numéros de votre combinaison, il est indispensable de finaliser le processus de confirmation d'inscription. À cet effet, vous recevrez après la validation de la combinaison un e-mail de confirmation à l'adresse e-mail que vous avez renseignée, qui comporte un lien de confirmation. Afin de finaliser le processus, vous devez cliquer sur ce lien de confirmation dans les 24 heures suivant la réception dudit e-mail. Si vous ne cliquez pas le lien dans le délai imparti, annulant ainsi le processus de confirmation, votre combinaison ne sera pas validée, et ne pourra donc pas participer au tirage au sort. </p>
                        <p id="u86240-27">&nbsp;</p>
                        <p id="u86240-29">Comment puis-je connaître les numéros gagnants actuels?</p>
                        <p id="u86240-31">Cliquez sur le bouton de menu "Statistiques". Vous y trouverez les numéros gagnants actuels ainsi que les numéros gagnants des tirages précédents.</p>
                        <p id="u86240-32">&nbsp;</p>
                        <p id="u86240-34">Comment le numéro complémentaire est-il déterminé?</p>
                        <p id="u86240-36">Le numéro complémentaire est déterminé en tant que dernier numéro de la combinaison, et il correspondra au dernier numéro que vous aurez sélectionné.</p>
                        <p id="u86240-37">&nbsp;</p>
                        <p><span className="Bold">Combien de numéros faut-il trouver pour remporter le million d'euros ? </span></p>
                        <p id="u86240-41">L'ensemble des 6 numéros ainsi que le numéro complémentaire doivent être identiques aux numéros gagnants pour que vous puissiez gagner la rente immédiate d'un million d'euros. Le numéro complémentaire est déterminé en tant que dernier numéro de la combinaison, et il correspondra au dernier numéro que vous aurez sélectionné.</p>
                        <p id="u86240-42">&nbsp;</p>
                        <p><span className="Bold">D'où vient le million?</span></p>
                        <p id="u86240-46">L'entreprise LUX Media AG couvre le prix principal en coopération avec SPS GmbH & Co. KG. Le million d'euros sera versé sous forme d'une pension immédiate : le gagnant recevra 3 333 € par mois sur une période de 299 mois et 3 433 € le 300ème mois. Le joueur n'est pas tenu d'apporter une mise initiale.</p>
                        <p id="u86240-47">&nbsp;</p>
                        <p><span className="Bold">Des taxes doivent-elles être payées sur les gains ?</span></p>
                        <p id="u86240-51">Non, ni le million ni les autres gains en espèces ne sont imposables.</p>
                        <p id="u86240-52">&nbsp;</p>
                        <p><span className="Bold">Que dois-je faire si j'oublie mon mot de passe ?</span></p>
                        <p id="u86240-56">Cliquez simplement sur "Mot de passe oublié" lors de la connexion. Vous recevrez votre mot de passe par e-mail.</p>
                        <p id="u86240-57">&nbsp;</p>
                        <p><span className="Bold">Comment puis-je changer ma combinaison ?</span></p>
                        <p id="u86240-61">ous pouvez modifier la combinaison jusqu'à son activation en cliquant sur le lien de confirmation.</p>
                        <p id="u86240-62">&nbsp;</p>
                        <p><span className="Bold">Où puis-je consulter les résultats de tirages passés ?</span></p>
                        <p id="u86240-66">Vous pouvez facilement consulter les résultats de tous les tirages passés en cliquant "Statistiques".</p>
                        <p id="u86240-67">&nbsp;</p>
                        <p><span className="Bold">Comment puis-je modifier mes données ?</span></p>
                        <p id="u86240-71">Une fois connecté à Unmillionparjour, vous pouvez compléter et/ou modifier vos informations personnelles en cliquant simplement sur "Mon profil".</p>
                        <p id="u86240-72">&nbsp;</p>
                        <p><span className="Bold">En cas de gain, comment est-il versé ?</span></p>
                        <p id="u86240-76">Les gains jusqu'à hauteur de 1000 € sont versés au gagnant en espèces ou bien par chèque. Le gain principal, à savoir le million d'euros, sera versé sous la forme d'une rente immédiate : le gagnant recevra, pour une période de 299 mois, un montant de 3 333 € par mois et le 300ème mois, un montant de 3 433 €.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="preload_images">
            <img className="preload" alt="" src="/img/x.jpg" />
          </div>
        </div>
      )
    }


    if(width > 768 && width <= 960) {
      return (
        <div className="breakpoint active" id="bp_960" >
          <div className="clearfix borderbox" id="page">
            <div className="clearfix grpelem" id="ptop"> <a className="anchor_item colelem shared_content" id="top"></a>
              <div className="browser_width colelem" id="u99590-bw" style={{height: "653px"}}>
                <div className="rounded-corners" id="u99590">
                  <div className="clearfix" id="u99590_align_to_page"> <a className="anchor_item grpelem shared_content"
                      id="ueber"></a>
                    <div className="clearfix grpelem shared_content" id="ppu513-6">
                      <div className="clearfix colelem" id="pu513-6">
                        <div className="h1---display-left-dark clearfix grpelem" id="u513-6">
                          <h1 id="u513-2">Misez tous les jours pour</h1>
                          <h1 id="u513-4">essayer de gagner tous les jours !</h1>
                        </div>
                        <div className="h2---display-left-dark clearfix grpelem" id="u514-10">
                          <h2 id="u514-6"><br/>Tentez votre chance pour remporter <br/>une rente mensuelle d'une valeur totale <br/>d'un million d'euros !</h2>
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix colelem" id="ppu540-6">
                <div className="clearfix grpelem" id="pu540-6">
                  <div className="clearfix colelem shared_content" id="u540-6">
                    <div className="h1---display-left-dark" id="u540-5">
                      <h1 id="u540-2">Jouer en</h1>
                      <h1 id="u540-4">toute confiance.</h1>
                    </div>
                  </div>
                  <div className="body clearfix colelem shared_content" id="u80669-7">
                    <p>Sur www.unmillionparjour, vous avez chaque jour la chance de remporter un million d'euros. Il vous suffit simplement de miser vos six numéros porte-bonheur et un numéro complémentaire. Tentez votre chance pour remporter une rente mensuelle d'une valeur totale d'un million d'euros !</p>
                    <p>&nbsp;</p>
                    <p><span id="u80669-4">Rien de plus simple : misez, inscrivez-vous et croisez les doigts pour gagner !</span></p>
                  </div>
                </div>
                <div className="body clearfix grpelem shared_content" id="u1194-6">
                  <p id="u1194-2">JOUEZ TOUS LES JOURS POUR ESSAYER DE GAGNER</p>
                  <p>Une fois par jour, vous pouvez miser votre grille personnelle chez Unmillionparjour. Toutes les combinaisons quotidiennes sont ensuite collectées et participent à un tirage qui a lieu 28 jours après l'activation de la combinaison. Pour activer les numéros de la combinaison, il est indispensable de finaliser le processus de confirmation d'inscription. À cet effet, vous recevrez après la validation de la combinaison un e-mail de confirmation à l'adresse e-mail que vous avez renseignée, qui comporte un lien de confirmation. Afin de finaliser le processus, vous devez cliquer sur ce lien de confirmation dans les 24 heures suivant la réception dudit e-mail. Si vous ne cliquez pas le lien dans le délai imparti, annulant ainsi le processus de confirmation, votre combinaison ne sera pas validée, et ne pourra donc pas participer au tirage au sort. Vous pouvez aisément visualiser les numéros gagnants dans la partie Statistiques d'Unmillionparjour.
                  </p>
                </div>
              </div><a className="nonblock nontext Button anim_swing btn-ghost-dark clearfix colelem shared_content"
                id="buttonu3678" href="/#Rang des gains">
                <div className="clearfix grpelem" id="u3679-4">
                  <p>RANG DES GAINS</p>
                </div>
              </a>
              <div className="browser_width colelem" id="u1239-bw" style={{height: "670px"}}>
                <div className="museBGSize" id="u1239">
                  <div className="clearfix" id="u1239_align_to_page"> <a className="anchor_item grpelem shared_content"
                      id="Rang des gains"></a>
                    <div className="clearfix grpelem" id="pu1305">
                      <div className="museBGSize rounded-corners colelem shared_content" id="u1305"></div>
                      <div className="h1---display-left-light clearfix colelem shared_content" id="u99429-8">
                        <h1 id="u99429-2">Mon conseil :</h1>
                        <h1 id="u99429-6">Misez tous les jours pour <br/>essayer de gagner tous les jours !</h1>
                      </div><a
                        className="nonblock nontext Button anim_swing transition rgba-background clearfix colelem shared_content"
                        id="buttonu1296" href="/#fragen">
                        <div className="clearfix grpelem" id="u1297-4">
                          <p>DES QUESTIONS?</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix colelem" id="pu5481">
                <div className="browser_width grpelem shared_content" style={{height: "418px"}} id="u5481-bw">
                  <div className="gradient" id="u5481"></div>
                </div>
                <div className="browser_width grpelem" id="u5484-8-bw" style={{height: "169px"}}>
                  <div className="clearfix shared_content" id="u5484-8">
                    <h1 className="h1---display-left-light" id="u5484-2">Rang des gains</h1>
                    <h2 className="h2---display-center-light" id="u5484-6">Consultez les résultats des tirages  <br/>actuels 
                      et passés.</h2>
                  </div>
                </div>
                <div className="PamphletWidget clearfix allow_click_through grpelem"  id="pamphletu95319">
                  <div className="position_content" id="pamphletu95319_position_content" style={{pointerEvents: "none"}}>
                    <div className="ThumbGroup clearfix colelem" id="u95339" style={{pointerEvents: "none", height: "81px"}}>
                      <div className="popup_anchor allow_click_through grpelem" id="u95344popup" style={{height: "81px", pointerEvents: "none"}} onClick={(e) => this.switchPane('winner_active')}>
                        <div className={winner_active ? "Thumb popup_element rounded-corners clearfix wp-tab-active PamphletThumbSelected" : "Thumb popup_element rounded-corners clearfix"}
                          style={{height: "64px"}} id="u95344">
                          <div className="transition clearfix grpelem shared_content" id="u95345-7">
                            <p id="u95345-3"><span id="u95345">&#8203;</span><span className="actAsInlineDiv normal_text"
                                id="u95346"><span className="actAsDiv excludeFromNormalFlow" id="u95347"><i
                                    className="fa fa-calculator"></i>
                                </span></span></p>
                            <p id="u95345-5">RÉSULTATS ACTUELS</p>
                          </div>
                        </div>
                      </div>
                      <div className="popup_anchor allow_click_through grpelem" id="u95348popup"
                        style={{height: "81px", pointerEvents: "none"}} onClick={(e) => this.switchPane('plan_active')}>
                        <div className={plan_active ? "Thumb popup_element rounded-corners clearfix wp-tab-active PamphletThumbSelected" : "Thumb popup_element rounded-corners clearfix"} style={{height: "66px"}} id="u95348">
                          <div className="transition clearfix grpelem shared_content" id="u95349-7">
                            <p id="u95349-3"><span id="u95349">&#8203;</span><span className="actAsInlineDiv normal_text"
                                id="u95350"><span className="actAsDiv excludeFromNormalFlow" id="u95351"><i
                                    className="fa fa-database"></i>
                                </span></span></p>
                            <p id="u95349-5">RANG DES GAINS</p>
                          </div>
                        </div>
                      </div>
                      <div className="popup_anchor allow_click_through grpelem" id="u95340popup"
                        style={{height: "81px", pointerEvents: "none"}} onClick={(e) => this.switchPane('statistic_active')}>
                        <div className={statistic_active ? "Thumb popup_element rounded-corners clearfix wp-tab-active PamphletThumbSelected" : "Thumb popup_element rounded-corners clearfix"} style={{height: "66px"}} id="u95340">
                          <div className="transition clearfix grpelem shared_content" id="u95341-7">
                            <p id="u95341-3"><span id="u95341">&#8203;</span><span className="actAsInlineDiv normal_text"
                                id="u95342"><span className="actAsDiv excludeFromNormalFlow" id="u95343"><i
                                    className="fa fa-graduation-cap"></i>
                                </span></span><span></span></p>
                            <p id="u95341-5">HISTORIQUE DES TIRAGES</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="popup_anchor allow_click_through colelem" id="u95323popup"
                      style={{height: "644px", pointerEvents: "none"}}>
                      <div className="ContainerGroup clearfix" style={{pointerEvents: "none", height: "644px"}} id="u95323">
                       { statistic_active &&
                          <div className="Container rounded-corners featured-news-container clearfix grpelem wp-panel wp-panel-active"id="u95334" style={{height: "620px"}}>
                            <div className="clearfix grpelem shared_content" id="u95335-35">
                              <p className="h2" id="u95335-3">S<span id="u95335-2">TATISTIQUES</span></p>
                              <p className="body" id="u95335-7">Consultez les résultats des tirages  actuels  Gewinnzahlen
                                abrufen<br/>et passés et vérifier si vos numéros en font partie.</p>
                                { somio_data &&
                                  <div dangerouslySetInnerHTML={{__html: somio_data.statistics}}>
                                  </div>
                                }
                             
                            </div>
                          </div>
                        }
                       { winner_active &&
                        <div className="Container featured-news-container clearfix grpelem wp-panel wp-panel-active" id="u95329" style={{height: "530px"}}>
                            <div className="clearfix grpelem shared_content" id="u95330-13">
                              <p className="h2" id="u95330-3">Dernier tirage  {somio_data && somio_data.winner_date}&nbsp;&nbsp; <span
                                  id="u95330-2"></span></p>
                              <p className="body" id="u95330-7">{somio_data && somio_data.winner_numbers}<span id="u95330-5">{somio_data && somio_data.winner_star}</span></p>
                              <p className="body" id="u95330-8">&nbsp;</p>

                              { customer &&
                                <>
                                  <p className="h2" id="u95330-3">Votre combinaison</p>
                                  <p className="body" id="u95330-7" style={{letterSpacing: '3px'}}>{customer && customer.somioTips.slice(0,6).join('    |    ')}<span id="u95330-5">    |   {customer && customer.somioTips[6]}</span></p>
                                </>
                              }
                              
                            </div>
                          </div>
                        }
                        { plan_active &&
                          <div className="Container featured-news-container clearfix grpelem wp-panel wp-panel-active" id="u95324" style={{height: "530px"}}>
                            <div className="clearfix grpelem shared_content" id="u95325-18">
                              <p className="h2" id="u95325-2">RANG DES GAINS</p>
                              <p className="body" id="u95325-4">Rang des gains vous trouverez ci-dessous notre rang des gains :</p>
                              <p className="body" id="u95325-6">6 bons numéros + numéro complémentaire : {somio_data && somio_data.plan_6_z} €</p>
                              <p className="body" id="u95325-7">&nbsp;</p>
                              <p className="body" id="u95325-9">6 bons numéros  : {somio_data && somio_data.plan_6} €</p>
                              <p className="body" id="u95325-10">&nbsp;</p>                      
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div><a className="anchor_item grpelem shared_content" id="geht"></a>
              </div>
              <div className="clearfix colelem" id="pu6123-6">
                <div className="h1---display-left-light clearfix grpelem shared_content" id="u6123-6">
                  <h1 id="u6123-4">) C'est aussi simple <br/>que cela</h1>
                </div>
                <div className="clip_frame grpelem" id="u6120">
                  <img className="block" alt="" src="images/so-gehts.png?crc=4189511356" id="u6120_img" />
                </div>
              </div>
              <div className="body clearfix colelem shared_content" id="u6124-4">
                <p>CONNECTEZ-VOUS - MISEZ - JOUEZ</p>
              </div>
              <div className="rounded-corners clearfix colelem shared_content" id="u6232">
                <div className="clearfix grpelem" id="u55809"> 
                    <p className="body" id="u6152-3"><span id="u6152">1. </span>Connectez-vous ou bien inscrivez-vous si vous êtes nouveau sur Unmillionparjour. Si vous êtes nouveau chez nous, vous recevrez un e-mail de confirmation à l'adresse que vous avez renseignée, dans lequel nous vous invitons à cliquer sur le lien de confirmation pour activer votre compte.</p>
                    <p className="body" id="u6152-4">&nbsp;</p>
                    <p className="body" id="u6152-7"><span id="u6152-5">2. </span>Saisissez vos 6 numéros porte-bonheur et votre numéro complémentaire.</p>
                    <p className="body" id="u6152-8">&nbsp;</p>
                    <p className="body" id="u6152-11"><span id="u6152-9">3. </span>Confirmez votre combinaison en cliquant sur "Misez maintenant ". Vous recevrez un e-mail de notre part comportant vos numéros choisis et un lien de confirmation. En cliquant sur le lien de confirmation, vous serez redirigé vers une de nos pages Unmillionsparjour où vous devrez saisir le code affiché et le confirmer après l'avoir saisi. Après confirmation de la saisie du code, votre combinaison sera officiellement enregistrée et participera au tirage au sort 28 jours plus tard. Si le processus de confirmation n'est pas terminé dans les 24 heures suivant la réception du lien de confirmation, votre combinaison ne sera malheureusement pas prise en compte.</p>
                  </div>
              </div><a className="anchor_item colelem shared_content" id="fragen"></a>
              <div className="browser_width colelem shared_content" id="u6591-bw" style={{height: "2160px"}}>
                <div id="u6591">
                  <div className="clearfix" id="u6591_align_to_page">
                    <a className="anchor_item grpelem" id="anmelden"></a>
                    <div className="clearfix grpelem" id="pu86248-4">
                      <div className="h1---display-left-light clearfix colelem" id="u86248-4">
                        <h1 id="u86248-2">Foire Aux Questions</h1>
                      </div>
                      <div className="body clearfix colelem" id="u86240-78">
                        <p id="u86240-2"><span id="u86240">À quelle fréquence puis-je jouer?</span></p>
                        <p id="u86240-4">Une fois inscrit, vous pouvez valider votre combinaison auprès d'Unmillionparjour une fois par jour. Votre combinaison participe au tirage au sort 28 jours après la validation de la combinaison. Pour finaliser l'activation des numéros de votre combinaison, il est indispensable de finaliser le processus de confirmation d'inscription. À cet effet, vous recevrez après la validation de la combinaison un e-mail de confirmation à l'adresse e-mail que vous avez renseignée, qui comporte un lien de confirmation. Afin de finaliser le processus, vous devez cliquer sur ce lien de confirmation dans les 24 heures suivant la réception dudit e-mail. Si vous ne cliquez pas le lien dans le délai imparti, annulant ainsi le processus de confirmation, votre combinaison ne sera pas validée, et ne pourra donc pas participer au tirage au sort. </p>
                        <p id="u86240-5">&nbsp;</p>
                        <p id="u86240-7">Comment puis-je valider ma combinaison ?</p>
                        <p id="u86240-8">&nbsp;</p>
                        <p id="u86240-9">&nbsp;</p>
                        <ol className="list0 nls-None" id="u86240-20">
                          <li id="u86240-13">Connectez-vous ou bien inscrivez-vous si vous êtes nouveau sur Unmillionparjour. Si vous êtes nouveau chez nous, vous recevrez un e-mail de confirmation à l'adresse que vous avez renseignée, dans lequel nous vous invitons à cliquer sur le lien de confirmation pour activer votre compte.<br/><br/></li>
                          <li id="u86240-17">Saisissez vos 6 numéros porte-bonheur et votre numéro complémentaire.<br/><br/></li>
                          <li id="u86240-19">Confirmez votre combinaison en cliquant sur "Misez maintenant ". Vous recevrez un e-mail de notre part comportant vos numéros choisis et un lien de confirmation. En cliquant sur le lien de confirmation, vous serez redirigé vers une de nos pages Unmillionsparjour où vous devrez saisir le code affiché et le confirmer après l'avoir saisi. Après confirmation de la saisie du code, votre combinaison sera officiellement enregistrée et participera au tirage au sort 28 jours plus tard. Si le processus de confirmation n'est pas terminé dans les 24 heures suivant la réception du lien de confirmation, votre combinaison ne sera malheureusement pas prise en compte.</li>
                        </ol>
                        <p id="u86240-21">&nbsp;</p>
                        <p id="u86240-22">&nbsp;</p>
                        <p id="u86240-24">Jusqu'à quand puis-je valider ma combinaison ?</p>
                        <p id="u86240-26">Toutes les combinaisons d'un jour participent au tirage au sort 28 jours après la validation desdites combinaisons. Pour finaliser l'activation des numéros de votre combinaison, il est indispensable de finaliser le processus de confirmation d'inscription. À cet effet, vous recevrez après la validation de la combinaison un e-mail de confirmation à l'adresse e-mail que vous avez renseignée, qui comporte un lien de confirmation. Afin de finaliser le processus, vous devez cliquer sur ce lien de confirmation dans les 24 heures suivant la réception dudit e-mail. Si vous ne cliquez pas le lien dans le délai imparti, annulant ainsi le processus de confirmation, votre combinaison ne sera pas validée, et ne pourra donc pas participer au tirage au sort. </p>
                        <p id="u86240-27">&nbsp;</p>
                        <p id="u86240-29">Comment puis-je connaître les numéros gagnants actuels?</p>
                        <p id="u86240-31">Cliquez sur le bouton de menu "Statistiques". Vous y trouverez les numéros gagnants actuels ainsi que les numéros gagnants des tirages précédents.</p>
                        <p id="u86240-32">&nbsp;</p>
                        <p id="u86240-34">Comment le numéro complémentaire est-il déterminé?</p>
                        <p id="u86240-36">Le numéro complémentaire est déterminé en tant que dernier numéro de la combinaison, et il correspondra au dernier numéro que vous aurez sélectionné..</p>
                        <p id="u86240-37">&nbsp;</p>
                        <p><span className="Bold">Combien de numéros faut-il trouver pour remporter le million d'euros ? </span></p>
                        <p id="u86240-41">L'ensemble des 6 numéros ainsi que le numéro complémentaire doivent être identiques aux numéros gagnants pour que vous puissiez gagner la rente immédiate d'un million d'euros. Le numéro complémentaire est déterminé en tant que dernier numéro de la combinaison, et il correspondra au dernier numéro que vous aurez sélectionné.</p>
                        <p id="u86240-42">&nbsp;</p>
                        <p><span className="Bold">D'où vient le million?</span></p>
                        <p id="u86240-46">L'entreprise LUX Media AG couvre le prix principal en coopération avec SPS GmbH & Co. KG. Le million d'euros sera versé sous forme d'une pension immédiate : le gagnant recevra 3 333 € par mois sur une période de 299 mois et 3 433 € le 300ème mois. Le joueur n'est pas tenu d'apporter une mise initiale.</p>
                        <p id="u86240-47">&nbsp;</p>
                        <p><span className="Bold">Des taxes doivent-elles être payées sur les gains ?</span></p>
                        <p id="u86240-51">Non, ni le million ni les autres gains en espèces ne sont imposables.</p>
                        <p id="u86240-52">&nbsp;</p>
                        <p><span className="Bold">Que dois-je faire si j'oublie mon mot de passe ?</span></p>
                        <p id="u86240-56">Cliquez simplement sur "Mot de passe oublié" lors de la connexion. Vous recevrez votre mot de passe par e-mail.</p>
                        <p id="u86240-57">&nbsp;</p>
                        <p><span className="Bold">Comment puis-je changer ma combinaison ?</span></p>
                        <p id="u86240-61">Vous pouvez modifier la combinaison jusqu'à son activation en cliquant sur le lien de confirmation.</p>
                        <p id="u86240-62">&nbsp;</p>
                        <p><span className="Bold">Où puis-je consulter les résultats de tirages passés ?</span></p>
                        <p id="u86240-66">Vous pouvez facilement consulter les résultats de tous les tirages passés en cliquant "Statistiques".</p>
                        <p id="u86240-67">&nbsp;</p>
                        <p><span className="Bold">Comment puis-je modifier mes données ?</span></p>
                        <p id="u86240-71">Une fois connecté à Unmillionparjour, vous pouvez compléter et/ou modifier vos informations personnelles en cliquant simplement sur "Mon profil".

</p>
                        <p id="u86240-72">&nbsp;</p>
                        <p><span className="Bold">En cas de gain, comment est-il versé ?</span></p>
                        <p id="u86240-76">Les gains jusqu'à hauteur de 1000 € sont versés au gagnant en espèces ou bien par chèque. Le gain principal, à savoir le million d'euros, sera versé sous la forme d'une rente immédiate : le gagnant recevra, pour une période de 299 mois, un montant de 3 333 € par mois et le 300ème mois, un montant de 3 433 €.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

		return (
			<div className="breakpoint active" id="bp_infinity">
        <div className="clearfix borderbox" id="page">
          <div className="clearfix grpelem" id="ptop"> <a className="anchor_item colelem shared_content" id="top"></a>
            <div className="browser_width colelem" id="u496-bw">
              <div className="museBGSize rounded-corners" id="u496">
                <div className="clearfix" id="u496_align_to_page">
                  <a className="anchor_item grpelem shared_content" id="ueber"></a>
                  <div className="clearfix grpelem shared_content" id="ppu513-6">
                    <div className="clearfix colelem" id="pu513-6">
                      <div className="h1---display-left-dark clearfix grpelem" id="u513-6" >
                        <h1 id="u513-2">Misez tous les jours pour</h1>
                        <h1 id="u513-4">essayer de gagner tous les jours !</h1>
                      </div>
                      <div className="h2---display-left-dark clearfix grpelem" id="u514-10" >
                        <h2 id="u514-6"><br/>Tentez votre chance pour remporter <br />une rente mensuelle d'une valeur totale <br />d'un million d'euros ! </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix colelem" id="ppu540-6">
              <div className="clearfix grpelem" id="pu540-6">
                <div className="clearfix colelem shared_content" id="u540-6" >
                  <div className="h1---display-left-dark" id="u540-5">
                    <h1 id="u540-2">Jouer en</h1>
                    <h1 id="u540-4">toute confiance.</h1>
                  </div>
                </div>
                <div className="body clearfix colelem shared_content" id="u80669-7" >
                  <p>Sur www.unmillionparjour, vous avez chaque jour la chance de remporter un million d'euros. Il vous suffit simplement de miser vos six numéros porte-bonheur et un numéro complémentaire. Tentez votre chance pour remporter une rente mensuelle d'une valeur totale d'un million d'euros !</p>
                  <p>&nbsp;</p>
                  <p><span id="u80669-4">Rien de plus simple : misez, inscrivez-vous et croisez les doigts pour gagner !</span></p>
                </div> <a className="nonblock nontext Button anim_swing btn-ghost-dark clearfix colelem shared_content"
                  id="buttonu3678" href="/#Rang des gains">
                  <div className="clearfix grpelem" id="u3679-4">
                    <p>RANG DES GAINS</p>
                  </div>
                </a>
              </div>
              <div className="body clearfix grpelem shared_content" id="u1194-6" >
                <p id="u1194-2">JOUEZ TOUS LES JOURS POUR ESSAYER DE GAGNER</p>
                <p>Une fois par jour, vous pouvez miser votre grille personnelle chez Unmillionparjour. Toutes les combinaisons quotidiennes sont ensuite collectées et participent à un tirage qui a lieu 28 jours après l'activation de la combinaison. Pour activer les numéros de la combinaison, il est indispensable de finaliser le processus de confirmation d'inscription. À cet effet, vous recevrez après la validation de la combinaison un e-mail de confirmation à l'adresse e-mail que vous avez renseignée, qui comporte un lien de confirmation. Afin de finaliser le processus, vous devez cliquer sur ce lien de confirmation dans les 24 heures suivant la réception dudit e-mail. Si vous ne cliquez pas le lien dans le délai imparti, annulant ainsi le processus de confirmation, votre combinaison ne sera pas validée, et ne pourra donc pas participer au tirage au sort. Vous pouvez aisément visualiser les numéros gagnants dans la partie Statistiques d'Unmillionparjour.
                </p>
              </div>
            </div>
            <div className="browser_width colelem" id="u1239-bw">
              <div className="museBGSize" id="u1239">
                <div className="clearfix" id="u1239_align_to_page">
                  <a className="anchor_item grpelem shared_content" id="Rang des gains"></a>
                  <div className="clearfix grpelem" id="pu1305">
                    <div className="museBGSize rounded-corners colelem shared_content" id="u1305">
                    </div>
                    <div className="h1---display-left-light clearfix colelem shared_content" id="u99429-8"
                      >
                      <h1 id="u99429-2">Mon conseil :</h1>
                      <h1 id="u99429-6">Misez tous les jours pour <br />essayer de gagner tous les jours !</h1>
                    </div> <a
                      className="nonblock nontext Button anim_swing transition rgba-background clearfix colelem shared_content"
                      id="buttonu1296" href="/#fragen">
                      <div className="clearfix grpelem" id="u1297-4">
                        <p>DES QUESTIONS?</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix colelem" id="pu5481">
              <div className="browser_width grpelem" id="u5481-bw">
                <div className="gradient" id="u5481">
                  <div className="clearfix" id="u5481_align_to_page">
                    <div className="clearfix grpelem shared_content" id="u5484-8" >
                      <h1 className="h1---display-left-light" id="u5484-2">Rang des gains</h1>
                      <h2 className="h2---display-center-light" id="u5484-6">Consultez les résultats des tirages 
                        <br />actuels 
                        et passés.</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="PamphletWidget clearfix allow_click_through grpelem" id="pamphletu5497" >
                <div className="popup_anchor allow_click_through grpelem"  id="u5501popup" style={{height: "775px", pointerEvents: "none"}}>
                <div className="ContainerGroup clearfix"  id="u5501"   style={{pointerEvents: "none", height: "775px"}}>
                  { winner_active &&
                    <div className="Container rounded-corners featured-news-container clearfix grpelem wp-panel wp-panel-active"  id="u5502" style={{height: "530px"}}>     
                      <div className="clearfix grpelem" id="u80829-13"     >
                        <p className="h2" id="u80829-3">Dernier tirage  {somio_data && somio_data.winner_date}&nbsp;&nbsp; <span id="u80829-2"></span></p>
                        <p className="body" id="u80829-7">{somio_data && somio_data.winner_numbers}<span id="u80829-5">{somio_data && somio_data.winner_star}</span></p>
                        
                        
                        { customer &&
                          <>
                            <p className="h2" id="u95330-3">Votre combinaison</p>
                            <p className="body" id="u95330-7" style={{letterSpacing: '3px'}}>{customer && customer.somioTips.slice(0,6).join('    |    ')}<span id="u95330-5">    |   {customer && customer.somioTips[6]}</span></p>
                          </>
                        }
                      </div>
                    </div>
                  }
                  { plan_active &&
                    <div className="Container featured-news-container clearfix grpelem wp-panel wp-panel-active"  id="u5505" style={{height: "530px"}}>
                      <div className="clearfix grpelem" id="u5716-19"     >
                        <p className="h2" id="u5716-3">Rang des gains&nbsp;&nbsp; <span id="u5716-2">---------</span></p>
                        <p className="body" id="u5716-5">Rang des gains vous trouverez ci-dessous notre rang des gains :</p>
                        <p className="body" id="u5716-7">6 bons numéros + numéro complémentaire : {somio_data && somio_data.plan_6_z} €</p>
                        <p className="body" id="u5716-8">&nbsp;</p>
                        <p className="body" id="u5716-10">6 bons numéros  : {somio_data && somio_data.plan_6} €</p>
                        <p className="body" id="u5716-11">&nbsp;</p>
                      </div>    
                    </div>
                  }
                  { statistic_active &&
                    <div className="Container featured-news-container clearfix grpelem wp-panel wp-panel-active"  id="u5504" style={{height: "668px"}}>
                      <div className="clearfix grpelem" id="u80877-37"     >
                        <p className="h2" id="u80877-5">S<span id="u80877-2">TATISTIQUES </span>&nbsp; <span id="u80877-4">---------</span></p>
                        <p className="body" id="u80877-9">Vous pouvez consulter ici les résultats des tirages actuels <br/>et passés et vérifier si vos numéros en font partie.</p>
                        { somio_data &&
                          <div dangerouslySetInnerHTML={{__html: somio_data.statistics}}>
                          </div>
                        }
                      </div>   
                    </div>
                  }
                </div>
                </div>
                <div className="ThumbGroup clearfix grpelem"  id="u5506"    style={{pointerEvents: "none", height: "81px"}}>
                <div className="popup_anchor allow_click_through grpelem"  id="u5509popup" style={{height: "81px", pointerEvents: "none"}} onClick={(e) => this.switchPane('winner_active')}>
                  <div className={winner_active ? "Thumb popup_element rounded-corners clearfix wp-tab-active PamphletThumbSelected" : "Thumb popup_element rounded-corners clearfix"}  id="u5509"  style={{height: "66px"}}>     <div className="transition clearfix grpelem" id="u5510-7"    >
                    <p id="u5510-3"><span id="u5510">&#8203;</span><span className="actAsInlineDiv normal_text" id="u5567"><span className="actAsDiv excludeFromNormalFlow" id="u5564"><i className="fa fa-calculator"></i>
              </span></span></p>
                    <p id="u5510-5">RÉSULTATS ACTUELS</p>
                  </div>    </div>
                </div>
                <div className="popup_anchor allow_click_through grpelem"  id="u5511popup" style={{height: "81px", pointerEvents: "none"}} onClick={(e) => this.switchPane('plan_active')}>
                  <div className={plan_active ? "Thumb popup_element rounded-corners clearfix wp-tab-active PamphletThumbSelected" : "Thumb popup_element rounded-corners clearfix"}  id="u5511"   style={{height: "66px"}}>     <div className="transition clearfix grpelem" id="u5512-7"    >
                    <p id="u5512-3"><span id="u5512">&#8203;</span><span className="actAsInlineDiv normal_text" id="u5594"><span className="actAsDiv excludeFromNormalFlow" id="u5591"><i className="fa fa-database"></i>
              </span></span></p>
                    <p id="u5512-5">RANG DES GAINS</p>
                  </div>    </div>
                </div>
                <div className="popup_anchor allow_click_through grpelem"  id="u5507popup" style={{height: "81px", pointerEvents: "none"}} onClick={(e) => this.switchPane('statistic_active')}>
                  <div className={statistic_active ? "Thumb popup_element rounded-corners clearfix wp-tab-active PamphletThumbSelected" : "Thumb popup_element rounded-corners clearfix"}  id="u5507"      aria-controls="u5504" style={{height: "66px"}}>     <div className="transition clearfix grpelem" id="u5508-7"    >
                    <p id="u5508-3"><span id="u5508">&#8203;</span><span className="actAsInlineDiv normal_text" id="u5602"><span className="actAsDiv excludeFromNormalFlow" id="u5599"><i className="fa fa-graduation-cap"></i>
              </span></span><span></span></p>
                    <p id="u5508-5">HISTORIQUE DES TIRAGES</p>
                  </div>    </div>
                </div>
                </div>
              </div>
              <a className="anchor_item grpelem shared_content" id="geht"></a>
            </div>
            <div className="clearfix colelem" id="ppu6123-6">
              <div className="clearfix grpelem" id="pu6123-6">
                <div className="h1---display-left-light clearfix colelem shared_content" id="u6123-6"
                  >
                  <h1 id="u6123-4">C'est aussi simple <br />que cela</h1>
                </div>
                <div className="body clearfix colelem shared_content" id="u6124-4" >
                  <p>CONNECTEZ-VOUS - MISEZ - JOUEZ</p>
                </div>
              </div>
              <div className="clip_frame grpelem" id="u6120"> <img className="block temp_no_img_src" id="u6120_img"
                  alt="" src="/img/so-gehts.png" />
              </div>
            </div>
            <div className="rounded-corners clearfix colelem shared_content" id="u6232">
              <div className="clearfix grpelem" id="u55809"> 
                  <p className="body" id="u6152-3"><span id="u6152">1. </span>Connectez-vous ou bien inscrivez-vous si vous êtes nouveau sur Unmillionparjour. Si vous êtes nouveau chez nous, vous recevrez un e-mail de confirmation à l'adresse que vous avez renseignée, dans lequel nous vous invitons à cliquer sur le lien de confirmation pour activer votre compte.</p>
                  <p className="body" id="u6152-4">&nbsp;</p>
                  <p className="body" id="u6152-7"><span id="u6152-5">2. </span>Saisissez vos 6 numéros porte-bonheur et votre numéro complémentaire.</p>
                  <p className="body" id="u6152-8">&nbsp;</p>
                  <p className="body" id="u6152-11"><span id="u6152-9">3. </span>Confirmez votre combinaison en cliquant sur "Misez maintenant ". Vous recevrez un e-mail de notre part comportant vos numéros choisis et un lien de confirmation. En cliquant sur le lien de confirmation, vous serez redirigé vers une de nos pages Unmillionsparjour où vous devrez saisir le code affiché et le confirmer après l'avoir saisi. Après confirmation de la saisie du code, votre combinaison sera officiellement enregistrée et participera au tirage au sort 28 jours plus tard. Si le processus de confirmation n'est pas terminé dans les 24 heures suivant la réception du lien de confirmation, votre combinaison ne sera malheureusement pas prise en compte.</p>
                 </div>
            </div>
            <a className="anchor_item colelem shared_content" id="fragen"></a>
            <div className="browser_width colelem shared_content" id="u6591-bw">
              <div id="u6591">
                <div className="clearfix" id="u6591_align_to_page">
                  <a className="anchor_item grpelem" id="anmelden"></a>
                  <div className="clearfix grpelem" id="pu86248-4">
                    <div className="h1---display-left-light clearfix colelem" id="u86248-4" >
                      <h1 id="u86248-2">Foire Aux Questions</h1>
                    </div>
                    <div className="body clearfix colelem" id="u86240-78" >
                      <p id="u86240-2"><span id="u86240">À quelle fréquence puis-je jouer?</span></p>
                      <p id="u86240-4">Une fois inscrit, vous pouvez valider votre combinaison auprès d'Unmillionparjour une fois par jour. Votre combinaison participe au tirage au sort 28 jours après la validation de la combinaison. Pour finaliser l'activation des numéros de votre combinaison, il est indispensable de finaliser le processus de confirmation d'inscription. À cet effet, vous recevrez après la validation de la combinaison un e-mail de confirmation à l'adresse e-mail que vous avez renseignée, qui comporte un lien de confirmation. Afin de finaliser le processus, vous devez cliquer sur ce lien de confirmation dans les 24 heures suivant la réception dudit e-mail. Si vous ne cliquez pas le lien dans le délai imparti, annulant ainsi le processus de confirmation, votre combinaison ne sera pas validée, et ne pourra donc pas participer au tirage au sort. </p>
                      <p id="u86240-5">&nbsp;</p>
                      <p id="u86240-7">Comment puis-je valider ma combinaison ?</p>
                      <p id="u86240-8">&nbsp;</p>
                      <p id="u86240-9">&nbsp;</p>
                      <ol className="list0 nls-None" id="u86240-20">
                        <li id="u86240-13">Connectez-vous ou bien inscrivez-vous si vous êtes nouveau sur Unmillionparjour. Si vous êtes nouveau chez nous, vous recevrez un e-mail de confirmation à l'adresse que vous avez renseignée, dans lequel nous vous invitons à cliquer sur le lien de confirmation pour activer votre compte.<br /><br />
                        </li>
                        <li id="u86240-17">Saisissez vos 6 numéros porte-bonheur et votre numéro complémentaire.<br /><br /></li>
                        <li id="u86240-19">Confirmez votre combinaison en cliquant sur "Misez maintenant ". Vous recevrez un e-mail de notre part comportant vos numéros choisis et un lien de confirmation. En cliquant sur le lien de confirmation, vous serez redirigé vers une de nos pages Unmillionsparjour où vous devrez saisir le code affiché et le confirmer après l'avoir saisi. Après confirmation de la saisie du code, votre combinaison sera officiellement enregistrée et participera au tirage au sort 28 jours plus tard. Si le processus de confirmation n'est pas terminé dans les 24 heures suivant la réception du lien de confirmation, votre combinaison ne sera malheureusement pas prise en compte.</li>
                      </ol>
                      <p id="u86240-21">&nbsp;</p>
                      <p id="u86240-22">&nbsp;</p>
                      <p id="u86240-24">Jusqu'à quand puis-je valider ma combinaison ?</p>
                      <p id="u86240-26">Toutes les combinaisons d'un jour participent au tirage au sort 28 jours après la validation desdites combinaisons. Pour finaliser l'activation des numéros de votre combinaison, il est indispensable de finaliser le processus de confirmation d'inscription. À cet effet, vous recevrez après la validation de la combinaison un e-mail de confirmation à l'adresse e-mail que vous avez renseignée, qui comporte un lien de confirmation. Afin de finaliser le processus, vous devez cliquer sur ce lien de confirmation dans les 24 heures suivant la réception dudit e-mail. Si vous ne cliquez pas le lien dans le délai imparti, annulant ainsi le processus de confirmation, votre combinaison ne sera pas validée, et ne pourra donc pas participer au tirage au sort. </p>
                      <p id="u86240-27">&nbsp;</p>
                      <p id="u86240-29">Comment puis-je connaître les numéros gagnants actuels?</p>
                      <p id="u86240-31">Cliquez sur le bouton de menu "Statistiques". Vous y trouverez les numéros gagnants actuels ainsi que les numéros gagnants des tirages précédents.</p>
                      <p id="u86240-32">&nbsp;</p>
                      <p id="u86240-34">Comment le numéro complémentaire est-il déterminé?</p>
                      <p id="u86240-36">Le numéro complémentaire est déterminé en tant que dernier numéro de la combinaison, et il correspondra au dernier numéro que vous aurez sélectionné.</p>
                      <p id="u86240-37">&nbsp;</p>
                      <p><span className="Bold">Combien de numéros faut-il trouver pour remporter le million d'euros ? </span></p>
                      <p id="u86240-41">L'ensemble des 6 numéros ainsi que le numéro complémentaire doivent être identiques aux numéros gagnants pour que vous puissiez gagner la rente immédiate d'un million d'euros. Le numéro complémentaire est déterminé en tant que dernier numéro de la combinaison, et il correspondra au dernier numéro que vous aurez sélectionné.</p>
                      <p id="u86240-42">&nbsp;</p>
                      <p><span className="Bold">D'où vient le million?</span></p>
                      <p id="u86240-46">L'entreprise LUX Media AG couvre le prix principal en coopération avec SPS GmbH & Co. KG. Le million d'euros sera versé sous forme d'une pension immédiate : le gagnant recevra 3 333 € par mois sur une période de 299 mois et 3 433 € le 300ème mois. Le joueur n'est pas tenu d'apporter une mise initiale.</p>
                      <p id="u86240-47">&nbsp;</p>
                      <p><span className="Bold">Des taxes doivent-elles être payées sur les gains ?</span></p>
                      <p id="u86240-51">Non, ni le million ni les autres gains en espèces ne sont imposables.</p>
                      <p id="u86240-52">&nbsp;</p>
                      <p><span className="Bold">Que dois-je faire si j'oublie mon mot de passe ?</span></p>
                      <p id="u86240-56">Cliquez simplement sur "Mot de passe oublié" lors de la connexion. Vous recevrez votre mot de passe par e-mail.</p>
                      <p id="u86240-57">&nbsp;</p>
                      <p><span className="Bold">Comment puis-je changer ma combinaison ?</span></p>
                      <p id="u86240-61">Vous pouvez modifier la combinaison jusqu'à son activation en cliquant sur le lien de confirmation.</p>
                      <p id="u86240-62">&nbsp;</p>
                      <p><span className="Bold">Où puis-je consulter les résultats de tirages passés ?</span></p>
                      <p id="u86240-66">Vous pouvez facilement consulter les résultats de tous les tirages passés en cliquant "Statistiques".</p>
                      <p id="u86240-67">&nbsp;</p>
                      <p><span className="Bold">Comment puis-je modifier mes données ?</span></p>
                      <p id="u86240-71">Une fois connecté à Unmillionparjour, vous pouvez compléter et/ou modifier vos informations personnelles en cliquant simplement sur "Mon profil".</p>
                      <p id="u86240-72">&nbsp;</p>
                      <p><span className="Bold">En cas de gain, comment est-il versé ?</span></p>
                      <p id="u86240-76">Les gains jusqu'à hauteur de 1000 € sont versés au gagnant en espèces ou bien par chèque. Le gain principal, à savoir le million d'euros, sera versé sous la forme d'une rente immédiate : le gagnant recevra, pour une période de 299 mois, un montant de 3 333 € par mois et le 300ème mois, un montant de 3 433 €.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
		)
	}
}

export default Home
