import React, { Component } from 'react'

class Header extends Component {
  constructor (props) {
    super(props)

    this.state = {
      mobile_menu_active: false,
      translations: {
        about: 'QUI SOMMES-NOUS ?',
        winning_plan: 'RANG DES GAINS',
        manual: 'AUSSI SIMPLE QUE CELA'
      }
    }

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({ mobile_menu_active: !this.state.mobile_menu_active })
  }
  
  render() {
    const { width } = this.props
    const { mobile_menu_active, translations } = this.state

    if(width <= 900) {
      return (
        <>
          <div className="clearfix grpelem" id="pu85098">
            <div className="browser_width shared_content" id="u85098-bw" style={{height: "auto"}}>
              <div id="u85098"></div>
            </div>
            <div className="clip_frame" id="u470">
              <img className="svg" width="219" height="52" alt="logo" src="/img/logo.png"
                id="u99478" />
            </div>
            <nav className="MenuBar museBGSize clearfix" id="menuu96441">
              <div className="MenuItemContainer clearfix grpelem" id="u96442">
                <div className="MenuItem MenuItemWithSubMenu museBGSize clearfix colelem" id="u96443" onClick={this.onClick} >
                  <div className="MenuItemLabel NoWrap clearfix grpelem" id="u96445-3">
                    <p>&nbsp;</p>
                  </div>
                </div>
                <div className="SubMenu MenuLevel1 clearfix" id="u96447" >
                  <ul className="SubMenuView clearfix colelem" id="u96448">
                    <li className="MenuItemContainer clearfix colelem" id="u96456"><a
                        className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing rgba-background clearfix grpelem"
                        id="u96457" href="#ueber">
                        <div className="MenuItemLabel NoWrap clearfix grpelem" id="u96460-4">
                          <p id="u96460-2"><span className="Navi" id="u96460">{translations.about}</span></p>
                        </div>
                      </a></li>
                    <li className="MenuItemContainer clearfix colelem" id="u97829"><a
                        className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing rgba-background clearfix grpelem"
                        id="u97830" href="/#{translations.winning_plan}">
                        <div className="MenuItemLabel NoWrap clearfix grpelem" id="u97832-4">
                          <p id="u97832-2"><span className="Navi" id="u97832">{translations.winning_plan}</span></p>
                        </div>
                      </a></li>
                    <li className="MenuItemContainer clearfix colelem" id="u97871"><a
                        className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing rgba-background clearfix grpelem"
                        id="u97872" href="/#geht">
                        <div className="MenuItemLabel NoWrap clearfix grpelem" id="u97873-4">
                          <p id="u97873-2"><span className="Navi" id="u97873">{translations.manual}</span></p>
                        </div>
                      </a></li>
                    <li className="MenuItemContainer clearfix colelem" id="u97913"><a
                        className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing rgba-background clearfix grpelem"
                        id="u97914" href="/#fragen">
                        <div className="MenuItemLabel NoWrap clearfix grpelem" id="u97916-4">
                          <p id="u97916-2"><span className="Navi" id="u97916">FAQ</span></p>
                        </div>
                      </a></li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <div style={{position: "fixed", top: "30px", right: "60px", margin: "0px", width: "39px"}} className="MenuBar popup_element">
            <div className="SubMenu MenuLevel1 clearfix" style={{display: `${mobile_menu_active ? 'block' : 'none'}`}}id="u96447">
                <ul className="SubMenuView clearfix colelem" id="u96448">
                    <li className="MenuItemContainer clearfix colelem" id="u96456">
                        <a className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing rgba-background clearfix grpelem" id="u96457" href="#ueber">
                            <div className="MenuItemLabel NoWrap clearfix grpelem" id="u96460-4">
                                <p id="u96460-2">
                                    <span className="Navi" id="u96460">{translations.about}</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    <li className="MenuItemContainer clearfix colelem" id="u97829">
                        <a className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing rgba-background clearfix grpelem" id="u97830" href="/#{translations.winning_plan}">
                            <div className="MenuItemLabel NoWrap clearfix grpelem" id="u97832-4">
                                <p id="u97832-2">
                                    <span className="Navi" id="u97832">{translations.winning_plan}</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    <li className="MenuItemContainer clearfix colelem" id="u97871">
                        <a className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing rgba-background clearfix grpelem" id="u97872" href="/#geht">
                            <div className="MenuItemLabel NoWrap clearfix grpelem" id="u97873-4">
                                <p id="u97873-2">
                                    <span className="Navi" id="u97873">{translations.manual}</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    <li className="MenuItemContainer clearfix colelem" id="u97913">
                        <a className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing rgba-background clearfix grpelem" id="u97914" href="/#fragen">
                            <div className="MenuItemLabel NoWrap clearfix grpelem" id="u97916-4">
                                <p id="u97916-2">
                                    <span className="Navi" id="u97916">FAQ</span>
                                </p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
          </div>
        </>
      )
    }

    return(
      <div className="clearfix grpelem" id="pu85098">
        <div className="browser_width shared_content" id="u85098-bw">
          <div id="u85098">
          </div>
        </div>
        <div className="clip_frame shared_content" id="u470">
          <img className="svg temp_no_img_src" id="u99478" width="224" height="53" alt="logo" src="/img/logo.png" />
        </div>
        <nav className="MenuBar clearfix" id="menuu6831">
          <div className="MenuItemContainer clearfix grpelem" id="u6846" style={{marginLeft: "-50px"}}>
            <a className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing clearfix colelem" id="u6849"
              href="/#ueber">
              <div className="MenuItemLabel NoWrap clearfix grpelem" id="u6852-4">
                <p id="u6852-2" className="shared_content"><span className="Navi" id="u6852">{translations.about}</span></p>
              </div>
            </a>
          </div>
          <div className="MenuItemContainer clearfix grpelem" id="u6853">
            <a className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing clearfix colelem" id="u6854"
              href="/#{translations.winning_plan}">
              <div className="MenuItemLabel NoWrap clearfix grpelem" id="u6856-4">
                <p id="u6856-2" className="shared_content"><span className="Navi" id="u6856">{translations.winning_plan}</span></p>
              </div>
            </a>
          </div>
          <div className="MenuItemContainer clearfix grpelem" id="u6832">
            <a className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing clearfix colelem" id="u6833"
              href="/#geht">
              <div className="MenuItemLabel NoWrap clearfix grpelem" id="u6834-4">
                <p id="u6834-2" className="shared_content"><span className="Navi" id="u6834">SO
                    GEHT’S</span></p>
              </div>
            </a>
          </div>
          <div className="MenuItemContainer clearfix grpelem" id="u6839">
            <a className="nonblock nontext MenuItem MenuItemWithSubMenu anim_swing clearfix colelem" id="u6840"
              href="/#fragen">
              <div className="MenuItemLabel NoWrap clearfix grpelem" id="u6842-4">
                <p id="u6842-2" className="shared_content"><span className="Navi" id="u6842">FAQ</span></p>
              </div>
            </a>
          </div>
        </nav>
      </div>
    )
  }
}

export default Header
