import 'promise-polyfill/src/polyfill'

import React, { Component } from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'

import Layout from './Layout'
import Home from './pages/Home'
import Privacy from './pages/Privacy'
import Imprint from './pages/Imprint'
import Terms from './pages/Terms'

import api from '../api/index'

if (window.location.hostname === 'whatever.de') {
	ReactGA.initialize('UA-000000-01')
	ReactGA.pageview(window.location.pathname + window.location.search)
}

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			height: window.innerHeight,
			width: window.innerWidth
		}

		this.updateDimensions = this.updateDimensions.bind(this);
	}

	async componentDidMount() {
		window.addEventListener("resize", this.updateDimensions)

		const response = await api.get('/unmillionparjour')
		const { winner_date, winner_numbers, winner_star, plan_6_z, plan_6, plan_5, statistics } = response.data.data

		this.setState({
			somio_data: {
				winner_date, winner_numbers, winner_star, plan_6_z, plan_6, plan_5, statistics: statistics.join('')
			}
		})
	}

	updateDimensions() {
		this.setState({
			height: window.innerHeight,
			width: window.innerWidth
		})
	}

	render() {
		const { width, height } = this.state
		const history = createBrowserHistory()
		return (
			<Router history={history}>
				<Layout width={width} height={height} history={history} {...this.props}>
					<Switch>
						<Route exact path="/" render={() => <Home width={width} height={height} history={history} {...this.state} />} />
						<Route exact path="/imprint/" render={() => <Imprint {...this.state} />} />
						<Route exact path="/privacy/" render={() => <Privacy {...this.state} />} />
						<Route exact path="/terms/" render={() => <Terms {...this.state} />} />
					</Switch>
				</Layout>
			</Router>
		)
	}
}

export default App
