import React from 'react'
import '../../../styles/datenschutz.css'

const Privacy = props => {
	return (
		<div className="breakpoint privacy active" id="bp_infinity">
      <div className="clearfix borderbox" id="page">
            <div className="clearfix grpelem shared_content" id="ptop">
                  <a className="anchor_item colelem" id="top"></a>
                  <div className="browser_width colelem" id="u116516-bw" style={{height: "4508px"}}>
                        <div id="u116516">
                              <div className="clearfix" id="u116516_align_to_page">
                                    <div className="h1---display-left-light clearfix colelem" id="u116517-4">
                                          <h1>Politique de confidentialité</h1>
                                    </div>
                                    <div className="clearfix colelem" id="u116518-268">
                                          <p id="u116518-3"><span className="Datenschutz-Copy"
                                                      id="u116518">&nbsp;</span><span className="Datenschutz-Head-2"
                                                      id="u116518-2">1. Confidentialité en un coup d'œil</span></p>
                                          <p id="u116518-4">&nbsp;</p>
                                          <p id="u116518-6"><span className="Datenschutz-Head-3"
                                                      id="u116518-5">Informations générales</span></p>
                                          <p id="u116518-7">&nbsp;</p>
                                          <p id="u116518-9"><span className="Datenschutz-Copy">Les informations suivantes donnent un aperçu simple de l’évolution de vos informations personnelles lorsque vous visitez notre site web. Les données personnelles sont toutes les données qui vous identifient personnellement. Des informations détaillées sur la protection des données sont disponibles dans notre politique de confidentialité.</span></p>
                                          <p id="u116518-10">&nbsp;</p>
                                          <p id="u116518-12"><span className="Datenschutz-Head-3"
                                                      id="u116518-11">Collecte de données sur notre site web</span></p>
                                          <p id="u116518-13">&nbsp;</p>
                                          <p id="u116518-15"><span className="Datenschutz-Head-3" id="u116518-14">Qui est responsable de la collecte des données sur ce site ?</span></p>
                                          <p id="u116518-16">&nbsp;</p>
                                          <p id="u116518-18"><span className="Datenschutz-Copy">Le traitement des données sur ce site est effectué par l'exploitant du site. Ses coordonnées se trouvent dans les mentions légales de ce site.

</span></p>
                                          <p id="u116518-19">&nbsp;</p>
                                          <p id="u116518-21"><span className="Datenschutz-Head-3" id="u116518-20">Comment recueillons-nous vos données ?</span></p>
                                          <p id="u116518-22">&nbsp;</p>
                                          <p id="u116518-24"><span className="Datenschutz-Copy">Vos données seront collectées d'une part, au travers de ce que vous nous communiquez. Il peut s'agir par exemple pour des données que vous saisissez dans un formulaire de contact.

</span></p>
                                          <p id="u116518-25">&nbsp;</p>
                                          <p id="u116518-27"><span className="Datenschutz-Copy">D'autres données sont automatiquement collectées lors de la visite du site via nos systèmes informatiques. Il s’agit avant tout de données techniques (navigateur Internet, système d’exploitation ou heure de visite de la page, par exemple). La collecte de ces informations est automatique dès que vous entrez sur notre site.</span></p>
                                          <p id="u116518-28">&nbsp;</p>
                                          <p id="u116518-30"><span className="Datenschutz-Head-3" id="u116518-29">Pourquoi utilisons-nous vos données ?</span></p>
                                          <p id="u116518-31">&nbsp;</p>
                                          <p id="u116518-33"><span className="Datenschutz-Copy">Une partie des données est collectée pour assurer une mise à disposition sans faille du site. D'autres données peuvent être utilisées pour analyser votre comportement d'utilisateur.</span></p>
                                          <p id="u116518-34">&nbsp;</p>
                                          <p id="u116518-36"><span className="Datenschutz-Head-3" id="u116518-35">Quels droits avez-vous sur vos données ?</span></p>
                                          <p id="u116518-37">&nbsp;</p>
                                          <p id="u116518-39"><span className="Datenschutz-Copy">A tout moment, vous avez le droit d'obtenir des informations gratuites sur l'origine, le destinataire et le but de vos données personnelles stockées. Vous avez également le droit de demander la correction, le blocage ou la suppression de ces données. Pour cela ainsi que pour d'autres questions concernant la protection des données, vous pouvez nous contacter à tout moment à l'adresse indiquée dans les mentions légales. De plus, vous avez le droit de faire appel à l'autorité de contrôle compétente.</span>
                                          </p>
                                          <p id="u116518-40">&nbsp;</p>
                                          <p id="u116518-42"><span className="Datenschutz-Head-3"
                                                      id="u116518-41">Outils d'analyse et outils tiers</span>
                                          </p>
                                          <p id="u116518-43">&nbsp;</p>
                                          <p id="u116518-45"><span className="Datenschutz-Copy">Lorsque vous visitez notre site web, votre comportement de navigation peut être évalué de manière statistique. Cela se produit surtout avec les cookies et avec ce qu'on appelle programmes d'analyse. L'analyse de votre comportement de navigation est généralement anonyme. Le comportement de navigation ne peut pas être retracé jusqu'à vous. Vous pouvez vous opposer à cette analyse ou l’empêcher en utilisant certains outils. Des informations détaillées peuvent être trouvées dans la politique de confidentialité suivante.

</span></p>
                                          <p id="u116518-46">&nbsp;</p>
                                          <p id="u116518-48"><span className="Datenschutz-Copy">Vous pouvez vous opposer à cette analyse. Nous vous informerons des possibilités d'objection dans cette politique de confidentialité.

</span></p>
                                          <p id="u116518-49">&nbsp;</p>
                                          <p id="u116518-51"><span className="Datenschutz-Head-2" id="u116518-50">2.
                                          Informations générales et informations obligatoires</span></p>
                                          <p id="u116518-52">&nbsp;</p>
                                          <p id="u116518-54"><span className="Datenschutz-Head-3"
                                                      id="u116518-53">
                                                      Politique de confidentialité
                                                      </span></p>
                                          <p id="u116518-55">&nbsp;</p>
                                          <p id="u116518-57"><span className="Datenschutz-Copy">Les exploitants de ces pages prennent très au sérieux la protection de vos données personnelles. Nous traitons vos données personnelles de manière confidentielle et conformément aux dispositions légales en matière de protection des données et à la présente politique de confidentialité.

</span></p>
                                          <p id="u116518-58">&nbsp;</p>
                                          <p id="u116518-60"><span className="Datenschutz-Copy">
Si vous utilisez ce site, différentes données personnelles seront collectées. Les informations personnelles sont des informations qui vous identifient personnellement. Cette politique de confidentialité explique quelles informations nous collectons et à quoi nous les utilisons. Il explique également comment et dans quel but cela se produit.
</span></p>
                                          <p id="u116518-61">&nbsp;</p>
                                          <p id="u116518-63"><span className="Datenschutz-Copy">Veuillez noter que la transmission de données sur Internet (par exemple, lors d'une communication par courrier électronique) peut comporter des failles de sécurité. Une protection complète des données contre l'accès de tiers n'est pas possible.</span></p>
                                          <p id="u116518-64">&nbsp;</p>
                                          <p id="u116518-66"><span className="Datenschutz-Head-3"
                                                      id="u116518-65">Note à l'organisme responsable</span></p>
                                          <p id="u116518-67">&nbsp;</p>
                                          <p id="u116518-69"><span className="Datenschutz-Copy">La société de traitement de données responsable sur ce site est :</span>
                                          </p>
                                          <p id="u116518-70">&nbsp;</p>
                                          <p id="u116518-72"><span className="Datenschutz-Copy">LUX SOLUTIONS AG</span>
                                          </p>
                                          <p id="u116518-73">&nbsp;</p>
                                          <p id="u116518-75"><span className="Datenschutz-Copy">Waistrooss 1d</span></p>
                                          <p id="u116518-77"><span className="Datenschutz-Copy">5445 Schengen</span></p>
                                          <p id="u116518-79"><span className="Datenschutz-Copy">Luxemburg</span></p>
                                          <p id="u116518-80">&nbsp;</p>
                                          <p id="u116518-82"><span className="Datenschutz-Copy">B151269</span></p>
                                          <p id="u116518-84"><span className="Datenschutz-Copy">Numéro d'identification fiscale :
                                                      20092231497</span></p>
                                          <p id="u116518-85">&nbsp;</p>
                                          <p id="u116518-87"><span className="Datenschutz-Copy">E-Mail:
                                                      info@unmillionparjour.de</span></p>
                                          <p id="u116518-88">&nbsp;</p>
                                          <p id="u116518-90"><span className="Datenschutz-Copy">L'entité responsable est la personne physique ou morale qui, seule ou de concert avec d'autres personnes, décide des finalités et des moyens du traitement des données à caractère personnel (telles que les noms, les adresses électroniques, etc.).</span></p>
                                          <p id="u116518-91">&nbsp;</p>
                                          <p id="u116518-93"><span className="Datenschutz-Head-3"
                                                      id="u116518-92">Révocation de votre consentement au traitement de données</span></p>
                                          <p id="u116518-94">&nbsp;</p>
                                          <p id="u116518-96"><span className="Datenschutz-Copy">De nombreuses opérations de traitement de données ne sont possibles qu'avec votre consentement explicite. Vous pouvez révoquer un consentement déjà donné à tout moment. Un message informel par courrier électronique à info@unmillionparjour.fr nous suffit. La légalité du traitement des données effectué jusqu'à la révocation n'est pas affectée par la révocation.</span></p>
                                          <p id="u116518-97">&nbsp;</p>
                                          <p id="u116518-99"><span className="Datenschutz-Head-3" id="u116518-98">Droit à la portabilité des données</span></p>
                                          <p id="u116518-100">&nbsp;</p>
                                          <p id="u116518-102"><span className="Datenschutz-Copy">Vous avez le droit de disposer des données que nous traitons sur la base de votre consentement ou en exécution d'un contrat automatiquement, en tant que telles ou à un tiers, dans un format standard lisible par machine. Si vous souhaitez transférer directement les données à une autre personne responsable, cela ne sera fait que dans la mesure où cela est techniquement réalisable.</span></p>
                                          <p id="u116518-103">&nbsp;</p>
                                          <p id="u116518-105"><span className="Datenschutz-Head-3" id="u116518-104">
Cryptage SSL ou TLS
</span></p>
                                          <p id="u116518-106">&nbsp;</p>
                                          <p id="u116518-108"><span className="Datenschutz-Copy">Ce site utilise, pour des raisons de sécurité et pour protéger la transmission de contenu confidentiel, tel que des commandes ou des demandes que vous nous envoyez en tant qu'exploitant du site, SSL ou. le cryptage TLS. Une connexion chiffrée est indiquée par la barre d'adresse du navigateur, qui passe de "http: //" à "https: //" et par l'icône de verrou dans la barre de votre navigateur.</span></p>
                                          <p id="u116518-109">&nbsp;</p>
                                          <p id="u116518-111"><span className="Datenschutz-Copy">Si le cryptage SSL ou TLS est activé, les données que vous nous soumettez ne peuvent pas être lues par des tiers.</span></p>
                                          <p id="u116518-112">&nbsp;</p>
                                          <p id="u116518-114"><span className="Datenschutz-Head-3"
                                                      id="u116518-113">Information, blocage, suppression</span></p>
                                          <p id="u116518-115">&nbsp;</p>
                                          <p id="u116518-117"><span className="Datenschutz-Copy">Dans le cadre des dispositions légales applicables, vous avez le droit à tout moment de fournir des informations gratuites sur vos données personnelles stockées, leur origine et leur destinataire ainsi que le but du traitement des données et, le cas échéant, le droit de les corriger, les bloquer ou les supprimer. Pour plus d'informations sur les données personnelles, veuillez nous contacter à tout moment à l'adresse indiquée dans les mentions légales.</span></p>
                                          <p id="u116518-118">&nbsp;</p>
                                          <p id="u116518-120"><span className="Datenschutz-Head-3"
                                                      id="u116518-119">Contestation contre les courriers publicitaires</span></p>
                                          <p id="u116518-121">&nbsp;</p>
                                          <p id="u116518-123"><span className="Datenschutz-Copy">L'utilisation des informations de contact présents dans les mentions légales pour l'envoi de matériel publicitaire et d'information non sollicités est par la présente interdite. Les exploitants des pages se réservent expressément le droit d'engager des poursuites en cas d'envoi non sollicité d'informations publicitaires, telles que des spams.

</span></p>
                                          <p id="u116518-124">&nbsp;</p>
                                          <p id="u116518-126"><span className="Datenschutz-Head-2" id="u116518-125">3.
                                          Collecte de données sur notre site web</span></p>
                                          <p id="u116518-127">&nbsp;</p>
                                          <p id="u116518-129"><span className="Datenschutz-Head-3"
                                                      id="u116518-128">Cookies</span></p>
                                          <p id="u116518-130">&nbsp;</p>
                                          <p id="u116518-132"><span className="Datenschutz-Copy">Les pages Internet utilisent en partie ce qu'on appelle des cookies. Les cookies ne nuisent pas à votre ordinateur et ne contiennent pas de virus. Les cookies servent à rendre notre offre plus conviviale, efficace et sécurisée. Les cookies sont de petits fichiers textes stockés sur votre ordinateur et stockés par votre navigateur.</span></p>
                                          <p id="u116518-133">&nbsp;</p>
                                          <p id="u116518-135"><span className="Datenschutz-Copy">La plupart des cookies que nous utilisons sont des "cookies de session". Ils seront automatiquement supprimés à la fin de votre visite. D'autres cookies restent stockés sur votre appareil jusqu'à ce que vous les supprimiez. Ces cookies nous permettent de reconnaître votre navigateur lors de votre prochaine visite.

</span></p>
                                          <p id="u116518-136">&nbsp;</p>
                                          <p id="u116518-138"><span className="Datenschutz-Copy">Vous pouvez configurer votre navigateur afin d'être informé de la configuration des cookies, de pouvoir autoriser les cookies uniquement dans des cas individuels, ou certains cas spécifiques, de pouvoir les supprimer de façon générale ou de configurer la suppression automatique des cookies lors de la fermeture du navigateur. La désactivation des cookies peut limiter les fonctionnalités de ce site.

</span></p>
                                          <p id="u116518-139">&nbsp;</p>
                                          <p id="u116518-141"><span className="Datenschutz-Copy">Les cookies nécessaires pour mener à bien le processus de communication électronique ou pour fournir certaines fonctions souhaitées (par exemple, la fonction de panier d'achat) sont traités conformément à l'article 6, paragraphe 1, de l'art. f du Règlement général sur la protection des données. L’exploitant du site web a un intérêt légitime dans le stockage de cookies pour la fourniture correcte d'un point de vue technique et optimisée de ses services. Si d'autres cookies (tels que les cookies permettant d'analyser votre comportement de navigation) sont stockés, ils seront traités séparément dans cette politique de confidentialité.

</span></p>
                                          <p id="u116518-142">&nbsp;</p>
                                          <p id="u116518-144"><span className="Datenschutz-Head-3"
                                                      id="u116518-143">
                                                      Les fichiers journaux de serveur
                                                      </span></p>
                                          <p id="u116518-145">&nbsp;</p>
                                          <p id="u116518-147"><span className="Datenschutz-Copy">Le fournisseur des pages collecte et stocke automatiquement des informations dans des fichiers appelés journaux de serveur, que votre navigateur nous transmet automatiquement. Ce sont:</span></p>
                                          <p id="u116518-148">&nbsp;</p>
                                          <ul className="list0 nls-None" id="u116518-151">
                                                <li id="u116518-150"><span className="Datenschutz-Copy">Type de navigateur et version du navigateur</span></li>
                                          </ul>
                                          <p id="u116518-152">&nbsp;</p>
                                          <ul className="list0 nls-None" id="u116518-155">
                                                <li id="u116518-154"><span className="Datenschutz-Copy">Système d'exploitation utilisé</span></li>
                                          </ul>
                                          <p id="u116518-156">&nbsp;</p>
                                          <ul className="list0 nls-None" id="u116518-159">
                                                <li id="u116518-158"><span className="Datenschutz-Copy">    URL de référence</span></li>
                                          </ul>
                                          <p id="u116518-160">&nbsp;</p>
                                          <ul className="list0 nls-None" id="u116518-163">
                                                <li id="u116518-162"><span className="Datenschutz-Copy">Nom d'hôte de l'ordinateur accédant</span></li>
                                          </ul>
                                          <p id="u116518-164">&nbsp;</p>
                                          <ul className="list0 nls-None" id="u116518-167">
                                                <li id="u116518-166"><span className="Datenschutz-Copy">    Heure de la demande du serveur</span></li>
                                          </ul>
                                          <p id="u116518-168">&nbsp;</p>
                                          <ul className="list0 nls-None" id="u116518-171">
                                                <li id="u116518-170"><span
                                                            className="Datenschutz-Copy">Adresse IP</span></li>
                                          </ul>
                                          <p id="u116518-172">&nbsp;</p>
                                          <p id="u116518-174"><span className="Datenschutz-Copy">Il n'y a pas de fusion de ces données avec d'autres sources de données.</span></p>
                                          <p id="u116518-175">&nbsp;</p>
                                          <p id="u116518-177"><span className="Datenschutz-Copy">La collecte de ces données est basée sur l’article 6, paragraphe 1, de l'art. f du Règlement général sur la protection des données. L’exploitant du site web a un intérêt légitime dans la présentation et l’optimisation techniques sans erreur de son site Web. Pour cela, les fichiers journaux du serveur doivent être enregistrés.</span></p>
                                          <p id="u116518-178">&nbsp;</p>
                                          <p id="u116518-180"><span className="Datenschutz-Head-3"
                                                      id="u116518-179">Contact</span></p>
                                          <p id="u116518-181">&nbsp;</p>
                                          <p id="u116518-183"><span className="Datenschutz-Copy">Si vous nous envoyez des demandes via le formulaire de contact, vos coordonnées du formulaire de demande, y compris les coordonnées que vous y avez fournies, seront stockées afin de traiter la demande et en cas de questions complémentaires. Nous ne partagerons pas ces informations sans votre consentement.

</span></p>
                                          <p id="u116518-184">&nbsp;</p>
                                          <p id="u116518-186"><span className="Datenschutz-Copy">Le traitement des données saisies dans le formulaire de contact se fait exclusivement sur la base de votre consentement (art. 6 (1) l'art. f du Règlement général sur la protection des données). Vous pouvez révoquer ce consentement à tout moment. Un message informel par courrier électronique à nous suffit. La légalité des traitements de données effectués jusqu'à la révocation n'est pas affectée par la révocation.

</span>
                                          </p>
                                          <p id="u116518-187">&nbsp;</p>
                                          <p id="u116518-189"><span className="Datenschutz-Copy">Les informations que vous fournissez dans le formulaire de contact resteront en notre possession jusqu'à ce que vous nous demandiez de les supprimer, de révoquer votre consentement au stockage ou de supprimer le but du stockage de données (par exemple, après le traitement de votre demande). Les dispositions légales obligatoires, notamment les durées de conservation, ne sont pas affectées.</span></p>
                                          <p id="u116518-190">&nbsp;</p>
                                          <p id="u116518-192"><span className="Datenschutz-Head-3"
                                                      id="u116518-191">Inscription sur ce site

                                                      </span></p>
                                          <p id="u116518-193">&nbsp;</p>
                                          <p id="u116518-195"><span className="Datenschutz-Copy">Vous pouvez vous inscrire sur notre site web pour utiliser des fonctionnalités supplémentaires sur le site. Nous n'utilisons les données saisies que pour utiliser l'offre ou le service pour lequel vous vous êtes inscrit. Les informations obligatoires demandées lors de l'inscription doivent être renseignées dans leur intégralité. Sinon, nous rejetterons l'enregistrement.</span></p>
                                          <p id="u116518-196">&nbsp;</p>
                                          <p id="u116518-198"><span className="Datenschutz-Copy">Pour les modifications importantes, par exemple dans le domaine de l'offre ou en cas de modifications techniques, nous utilisons l'adresse e-mail spécifiée lors de l'inscription pour vous informer de cette manière.

</span></p>
                                          <p id="u116518-199">&nbsp;</p>
                                          <p id="u116518-201"><span className="Datenschutz-Copy">Le traitement des données saisies lors de l'enregistrement a lieu sur la base de votre consentement (art. 6 (1) l'art. f du Règlement général sur la protection des données). Vous pouvez révoquer votre consentement à tout moment. Un message informel par courrier électronique à nous suffit. La légalité du traitement de données déjà terminé n’est pas affectée par la révocation.</span>
                                          </p>
                                          <p id="u116518-202">&nbsp;</p>
                                          <p id="u116518-204"><span className="Datenschutz-Copy">Les données collectées lors de l'inscription seront stockées par nous tant que vous êtes enregistrés sur notre site web et seront ensuite supprimées. Les délais de conservation légaux ne sont pas affectés.</span></p>
                                          <p id="u116518-205">&nbsp;</p>
                                          <p id="u116518-207"><span className="Datenschutz-Head-3"
                                                      id="u116518-206">Données de traitement (données client et contrat)</span></p>
                                          <p id="u116518-208">&nbsp;</p>
                                          <p id="u116518-210"><span className="Datenschutz-Copy">Nous collectons, traitons et utilisons des données personnelles uniquement dans la mesure où elles sont nécessaires à l'établissement, au contenu ou à la modification de la relation juridique (données boursières). Cela se fait sur la base de l’article 6, paragraphe 1, l'art. f du Règlement général sur la protection des données, qui permet le traitement de données pour l'exécution d'un contrat ou de mesures précontractuelles. Nous collectons, traitons et utilisons uniquement des données personnelles sur l'utilisation de nos pages Internet (données d'utilisateur) dans la mesure où cela est nécessaire pour permettre à l'utilisateur d'utiliser le service ou de le facturer.

</span></p>
                                          <p id="u116518-211">&nbsp;</p>
                                          <p id="u116518-213"><span className="Datenschutz-Copy">Les données client collectées seront supprimées après la finalisation de la commande ou la fin de la relation commerciale. Les délais de conservation légaux ne sont pas affectés.</span></p>
                                          <p id="u116518-214">&nbsp;</p>
                                          <p id="u116518-215">&nbsp;</p>
                                          <p id="u116518-217"><span className="Datenschutz-Head-2" id="u116518-216">4.
                                                      AOutils d'analyse et publicité</span></p>
                                          <p id="u116518-218">&nbsp;</p>
                                          <p id="u116518-220"><span className="Datenschutz-Head-3"
                                                      id="u116518-219">Google Analytics</span></p>
                                          <p id="u116518-221">&nbsp;</p>
                                          <p id="u116518-223"><span className="Datenschutz-Copy">Ce site web utilise des fonctions du service d'analyse Web Google Analytics. Le fournisseur est Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis.

 

                                          <br/><br/>
Google Analytics utilise des "cookies". Ce sont des fichiers textes qui sont stockés sur votre ordinateur et qui permettent une analyse de l’utilisation que vous faites du site. Les informations générées par le cookie concernant votre utilisation de ce site web sont généralement transmises à un serveur de Google aux États-Unis et y sont stockées.

 <br/><br/>

Les cookies de Google Analytics sont stockés sur la base de l’article 6, paragraphe 1, l'art. f du Règlement général sur la protection des données. L’exploitant du site web a un intérêt légitime à analyser le comportement des utilisateurs afin d’optimiser à la fois son site Web et sa publicité.

</span></p>
                                          <p id="u116518-230">&nbsp;</p>
                                          <p id="u116518-232"><span className="Datenschutz-Head-3" id="u116518-231">Anonymisation IP</span></p>
                                          <p id="u116518-233">&nbsp;</p>
                                          <p id="u116518-235"><span className="Datenschutz-Copy">Nous avons activé la fonction anonymisation IP sur ce site. Votre adresse IP sera alors raccourcie par Google dans les États membres de l'Union européenne ou par d'autres États membres ayant signé le traité sur l'Espace économique européen avant d'être transmise aux États-Unis. Dans des cas exceptionnels, l’adresse IP complète est envoyée à un serveur de Google aux États-Unis et abrégée à cet emplacement. Google utilisera ces informations pour évaluer votre utilisation du site Web, compiler des rapports sur l'activité du site Web et fournir d'autres services liés à l'activité du site Web et à l'utilisation d'Internet à son exploitant. L'adresse IP fournie par Google Analytics dans le cadre de Google Analytics ne sera pas fusionnée avec d'autres informations de Google.

</span></p>
                                          <p id="u116518-236">&nbsp;</p>
                                          <p id="u116518-238"><span className="Datenschutz-Head-3"
                                                      id="u116518-237">Plugin de navigateur</span></p>
                                          <p id="u116518-239">&nbsp;</p>
                                          <p id="u116518-241"><span className="Datenschutz-Copy">Vous pouvez empêcher le stockage de cookies en définissant les paramètres correspondants de votre logiciel de navigation. Cependant, nous vous précisons que, dans ce cas, vous ne pourrez peut-être pas utiliser toutes les fonctions de ce site web. En outre, vous pouvez empêcher la collecte par Google des données générées par le cookie et liées à votre utilisation du site web (y compris votre adresse IP), ainsi que le traitement de ces données par Google en téléchargeant le plug-in de navigateur disponible sous le lien suivant et en l'installant : https://tools.google.com/dlpage/gaoptout?hl=fr.</span></p>
                                          <p id="u116518-242">&nbsp;</p>
                                          <p id="u116518-244"><span className="Datenschutz-Head-3"
                                                      id="u116518-243">Opposition à la collecte de données</span></p>
                                          <p id="u116518-245">&nbsp;</p>
                                          <p id="u116518-247"><span className="Datenschutz-Copy">Vous pouvez empêcher la collecte de vos données par Google Analytics en cliquant sur le lien ci-dessous. Un cookie de désinscription sera créé pour empêcher la collecte de vos données lors de vos prochaines visites sur ce site : désactivez Google Analytics.

</span></p>
                                          <p id="u116518-248">&nbsp;</p>
                                          <p id="u116518-250"><span className="Datenschutz-Copy">Pour plus d'informations sur la gestion des données utilisateur dans Google Analytics, consultez les règles de confidentialité de Google: https://support.google.com/analytics/answer/6004245?hl=fr.</span>
                                          </p>
                                          <p id="u116518-251">&nbsp;</p>
                                          <p id="u116518-252">&nbsp;</p>
                                          <p id="u116518-254"><span className="Datenschutz-Head-2" id="u116518-253">5.
                                                      Newsletter</span></p>
                                          <p id="u116518-255">&nbsp;</p>
                                          <p id="u116518-257"><span className="Datenschutz-Head-3"
                                                      id="u116518-256">Données Newsletter</span></p>
                                          <p id="u116518-258">&nbsp;</p>
                                          <p id="u116518-260"><span className="Datenschutz-Copy">Si vous souhaitez recevoir la la newsletter proposée sur le site web, nous avons besoin d'une adresse électronique de votre part, ainsi que des informations nous permettant de vérifier que vous êtes le propriétaire de l'adresse électronique fournie et que vous acceptez de recevoir la lettre d'information. D'autres données ne sont pas collectées ou uniquement sur une base volontaire. Nous utilisons ces données exclusivement pour la livraison des informations demandées et ne les transmettons pas à des tiers.

 <br/><br/>

Le traitement des données saisies dans le formulaire d'inscription à la newsletter se fait exclusivement sur la base de votre consentement (art. 6 (1) l'art. f du Règlement général sur la protection des données). Vous pouvez révoquer votre consentement au stockage des données, à l'adresse e-mail et à son utilisation pour l'envoi de la newsletter à tout moment, par exemple via le lien "désinscription" de la newsletter. La légalité des traitements de données déjà achevés reste inchangée par la révocation.

<br/><br/>

Les données déposées auprès de nous dans le but d’obtenir la newsletter seront stockées dans la newsletter jusqu’à votre inscription et supprimées après annulation de la newsletter. Les données qui ont été stockées à d'autres fins chez nous (telles que les adresses de messagerie de l'espace personnel) ne sont pas affectées.
</span></p>
                                          <p className="body" id="u116518-266">&nbsp;</p>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
	</div>
	)
}

export default Privacy
