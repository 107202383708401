const configs = [ 
  { 
      host: 'unmillionparjour.fr', 
      apiUrl: 'https://api.thunderchild.es/easymillions', 
      apiKey: 'easymillions-fr' 
  }, 
  { 
      host: 'www.unmillionparjour.fr', 
      apiUrl: 'https://api.thunderchild.es/easymillions', 
      apiKey: 'easymillions-fr' 
  } 
] 

const config = configs.find(config => config.host === window.location.hostname) 

export default config || { 
  host: 'localhost', 
  apiUrl: 'http://localhost:3160/easymillions', 
  apiKey: 'easymillions-fr' 
} 
