import axios from 'axios' 
 
import config from '../config' 
 
export default axios.create({ 
    baseURL: config.apiUrl, 
    headers: { 
        'access-token': config.apiKey ,
        'cache-control': 'no-cache',
        'Connection': 'keep-alive',
        'accept-encoding': 'gzip, deflate',
        'Cache-Control': 'no-cache',
        'Accept': '*/*'
    } 
})