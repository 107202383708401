import React from 'react'
import '../../../styles/impressum.css'

const Imprint = props => {
	return (
		<div className="breakpoint imprint active" id="bp_infinity">
      <div className="clearfix borderbox" id="page">
            <div className="clearfix grpelem shared_content" id="ptop">
                  <a className="anchor_item colelem" id="top"></a>
                  <div className="browser_width colelem" id="u117316-bw">
                        <div id="u117316">
                              <div className="clearfix" id="u117316_align_to_page">
                                    <div className="h1---display-left-light clearfix colelem" id="u117315-4">
                                          <h1>Mentions légales</h1>
                                    </div>
                                    <div className="body clearfix colelem" id="u117314-24">
                                          <p id="u117314-2"><span className="Bold">Information conformément à l'article 5 de la Loi sur les Télémedias :</span></p>
                                          <p id="u117314-4">LUX SOLUTIONS AG</p>
                                          <p id="u117314-5">&nbsp;</p>
                                          <p id="u117314-7">Waistrooss 1d</p>
                                          <p id="u117314-9">L-5445 Schengen</p>
                                          <p id="u117314-10">&nbsp;</p>
                                          <p id="u117314-12">B151269</p>
                                          <p id="u117314-14">Numéro au registre des sociétés : 20092231497</p>
                                          <p id="u117314-15">&nbsp;</p>
                                          <p id="u117314-17">Gérante :</p>
                                          <p id="u117314-19">Rosemarie Wirtz</p>
                                          <p id="u117314-20">&nbsp;</p>
                                          <p id="u117314-22">info@unmillionparjour.de</p>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
		</div>
	)
}

export default Imprint
