import React, { Component } from 'react'

import Header from './Header'
import Footer from './Footer'

class layout extends Component {
	constructor (props) {
    super(props)
	}
	
	render() {
		const { children } = this.props


		return(
			<div className="js html css_verticalspacer wf-proximanova-n1-active wf-proximanova-n7-active wf-proximanova-n6-active wf-proximanova-n3-active wf-active wf-alike-n4-active wf-opensans-n7-active wf-opensans-n6-active wf-sourcesanspro-n4-active wf-opensans-n4-active fa-events-icons-ready"> 
				<Header {...this.props} />
				{ children }
				<Footer {...this.props} />
			</div>
		)
	}
}

export default layout
