import React, { Component } from 'react'

import '../../styles/index.css'

class Footer extends Component {
  constructor (props) {
    super(props)
  }
  
  render() {
    const { width, history } = this.props

    const path = history.location.pathname

    let height_spacer = '300px'
    if(width < 960 && width >= 900) {
      height_spacer = '600px'
    }

    if(width < 900 && width >= 770) {
      height_spacer = '850px'
    }

    if(width > 1100) {
      height_spacer = "150px"
    }

    if(width <= 768) {
      return (
        <>
          <div className="verticalspacer" style={{height: "300px"}}></div>
          <div className="clearfix grpelem" id="pu85835">
            <div className="clearfix colelem" id="u85835">
              <div className="clip_frame colelem" id="u85837">
                <img className="svg" id="u99496" width="194" height="46" alt="" src="/img/logo_inverse.png" />
              </div>
              <div className="clearfix colelem" id="u85839-4">
                <p>JOUEZ TOUS LES JOURS POUR ESSAYER DE GAGNER</p>
              </div>
              <div className="clearfix colelem" id="u85836-12">
                <p id="u85836-2">LUX SOLUTIONS AG</p>
                <p id="u85836-4">Waistrooss 1d | L-5445 Schengen | B151269 | 20092231497</p>
                <p id="u85836-5">&nbsp;</p>
                <p id="u85836-7">Gérante : Rosemarie Wirtz</p>
                <p id="u85836-8">&nbsp;</p>
                <p className="body" id="u85836-10">info@unmillionparjour.fr</p>
              </div>
            </div>
            <div className="clearfix colelem shared_content" id="u122055">
              <div className="pointer_cursor rounded-corners clearfix grpelem" id="u122057">
                <a className="block anim_swing" href="/#top"></a> <a className="nonblock nontext anim_swing grpelem"
                  id="u122056" href="#top"><i className="fa fa-chevron-up"></i>
                </a> </div>
            </div>
            <div className="browser_width colelem" style={{height: "148px"}} id="u2223-bw">
              <div className="" id="u2223">
                <div className="clearfix" id="u2223_align_to_page">
                  <div className="Normal-wei clearfix grpelem shared_content" id="u2163-12">
                    <p>© 2019 · unmillionparjour.fr | <a className="nonblock" href="/imprint">Mentions légales</a> | <a
                        className="nonblock" href="/privacy">Politique de confidentialité</a> | <a className="nonblock"
                        href="/terms">Conditions Générales</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }

    return(
      <>
        <div className="verticalspacer" style={{height: `${height_spacer}`}}></div>
        <div className="clearfix grpelem shared_content" id="pu85775">
          <div className="clearfix colelem" id="u85775">
            <div className="clip_frame colelem" id="u85778"> <img className="svg" id="u99539" width="272" height="65" alt="" src="/img/logo_inverse.png" />
            </div>
            <div className="clearfix colelem" id="u85777-4">
              <p>JOUEZ TOUS LES JOURS POUR ESSAYER DE GAGNER</p>
            </div>
            <div className="clearfix colelem" id="u85776-12">
              <p id="u85776-2">LUX SOLUTIONS AG</p>
              <p id="u85776-4">Waistrooss 1d | L-5445 Schengen | B151269 | 20092231497</p>
              <p id="u85776-5">&nbsp;</p>
              <p id="u85776-7">Gérante : Rosemarie Wirtz</p>
              <p id="u85776-8">&nbsp;</p>
              <p className="body" id="u85776-10">info@unmillionparjour.fr</p>
            </div>
          </div>
          <div className="browser_width colelem" style={{height: "94px"}} id="u2223-bw">
            <div className="" id="u2223">
              <div className="clearfix" id="u2223_align_to_page">
                <div className="position_content" id="u2223_position_content">
                  <div className="clearfix colelem shared_content" id="u122055">
                    <div className="pointer_cursor rounded-corners clearfix grpelem" id="u122057"> <a
                        className="block anim_swing" href="#top">
                      </a> <a className="nonblock nontext anim_swing grpelem" id="u122056" href="/#top">
                        <i className="fa fa-chevron-up"></i>
                      </a> </div>
                  </div>
                  <div className="Normal-wei clearfix colelem shared_content" id="u2163-12">
                    <p>© 2019 · unmillionparjour.fr | <a className="nonblock" href="/imprint">Mentions légales</a> | <a
                        className="nonblock" href="/privacy">Politique de confidentialité</a> | <a className="nonblock"
                        href="/terms">Conditions Générales</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Footer
